<template>
  <div class="select select--empty">
    <div class="select__control">
      <div class="select__icon select__icon--bedrooms"></div>
      <div class="select__content">
        <span v-if="bedrooms[0] == min && bedrooms[1] == max">{{i18n('search.bedrooms')}}</span>
        <span v-else>{{bedrooms[0]}} - {{bedrooms[1]}}<span v-if="bedrooms[1] == max">+</span></span>
      </div>
      <div class="select__arrow"></div>
    </div>
    <div class="select__dropdown select__dropdown--bedrooms">
      <div class="select__dropdown__body">
        <div class="row row--small mb-2">
          <div class="col text-left">
            {{bedrooms[0]}}
            <!-- <input type="text" v-bind:value="bedrooms[0]" class="form-control text-left form-control-sm"
            v-on:change="fillMin"> -->
          </div>
          <div class="col text-right">
            {{bedrooms[1]}}
            <span v-if="bedrooms[1] == max">+</span>
            <!-- <input type="text" v-bind:value="bedrooms[1]" class="form-control text-right form-control-sm"
            v-on:change="fillMax"> -->
          </div>
        </div>
        <vue-slider v-model="bedrooms" v-bind:min="min" v-bind:max="max" dotSize="20" tooltip="none" v-on:change="updateSelection"></vue-slider>
      </div>
    </div>
  </div>
</template>

<script>
import Vue from 'vue'
import VueSlider from 'vue-slider-component'
import i18nmixin from '../customs/i18nmixin'

export default {
  name: 'search-select-bedrooms',
  mixins: [i18nmixin],
  props: ['selection'],
  components: {
    VueSlider
  },
  data: function() {
    return {
      bedrooms: [],
      min: 0,
      max: 7,
      timeout: 0,
    }
  },
  mounted: function() {
    if(this.selection.bedrooms) {
      this.bedrooms = this.selection.bedrooms;
    } else {
      this.bedrooms = [this.min, this.max];
    }
  },
  watch: {
    selection: function(newVal) {
    }
  },
  methods: {
    fillMin(e) {
      var min = Math.min((parseInt(e.target.value) || 0), this.bedrooms[1]);
      min = Math.max(this.min, min);
      Vue.set(this.bedrooms, 0, min);
    },
    fillMax(e) {
      var max = Math.max((parseInt(e.target.value) || 0), this.bedrooms[0]);
      this.max = Math.max(this.max, max+1);
      Vue.set(this.bedrooms, 1, max);
    },
    updateSelection() {
      var self = this;
      clearTimeout(this.timeout);
      this.timeout = setTimeout(function() {
        if(self.bedrooms[0] == self.min && self.bedrooms[1] == self.max) {
          self.$emit('update', {type: 'bedrooms', value: false});
        } else {
          self.$emit('update', {type: 'bedrooms', value: self.bedrooms});
        }
      }, 800);
    }
  }
}
</script>
