import cable from "actioncable";

function createChannel(...args) {
  if (!window.consumer) {
    window.consumer = cable.createConsumer();
  }

  return window.consumer.subscriptions.create(...args);
}
export default createChannel;
