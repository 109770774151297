<template>
  <div class="search__form-group">
    <div class="row">
      <div class="col-6">
        <label v-bind:for="'moreCheckbox-'+name">{{label}}</label>
      </div>
      <div class="col-6 text-right">
        <div class="mt-1 custom-control custom-checkbox">
          <input type="checkbox" class="custom-control-input" v-model="model" v-bind:name="name" v-bind:value="value" v-bind:id="'moreCheckbox-'+name" v-on:change="$emit('update', {type: name, value: model})">
          <label class="custom-control-label" v-bind:for="'moreCheckbox-'+name"></label>
        </div>
      </div>
    </div>
  </div>
</template>

<script>
import Vue from 'vue'

export default {
  name: 'search-more-checkbox',
  props: ['label', 'name', 'value'],
  components: {
  },
  data: function() {
    return {
      model: 0
    }
  },
  mounted: function(){
    this.model = this.value;
  },
  watch: {
    value: function(val) {
      this.model = val;
    }
  },
  methods: {
  }
}
</script>
