<template>
  <div class="">
    <div class="search__title">{{i18n('search.service.title.first_line')}}<br />{{i18n('search.service.title.second_line')}}</div>
    <div class="form-group">
      <search-select-location></search-select-location>
    </div>
    <div class="form-group">
      <search-select-keys v-model="offer_types"></search-select-keys>
    </div>
    <div class="d-lg-none">
      <button type="button" class="btn btn-danger btn-block mt-2">{{i18n('search.find_service')}}</button>
    </div>
  </div>
</template>

<script>
import Vue from 'vue'
import SearchSelectKeys from './search-select-keys.vue'
import SearchSelectPropertyTypes from './search-select-property-types.vue'
import SearchSelectLocation from './search-select-location.vue'
import SearchSelectPrice from './search-select-price.vue'
import SearchSelectBedrooms from './search-select-bedrooms.vue'
import SearchMorePlusMinus from './search-more-plus-minus.vue'
import SearchMoreCheckbox from './search-more-checkbox.vue'
import SearchMoreFromTo from './search-more-from-to.vue'
import i18nmixin from '../customs/i18nmixin'

export default {
  name: 'search-service',
  mixins: [i18nmixin],
  components: {
    SearchSelectKeys,
    SearchSelectPropertyTypes,
    SearchSelectLocation,
    SearchSelectPrice,
    SearchSelectBedrooms,
    SearchMorePlusMinus,
    SearchMoreCheckbox,
    SearchMoreFromTo
  },
  data: function() {
    return {
      offer_types: [
        {
          label: 'Service',
          name: 'service',
          selected: true,
        },
        {
          label: 'Observer',
          name: 'observer',
          selected: false,
        }
      ],
      salons: 1,
      bathrooms: 1,
      garages: 1,
      pool: false,
      balcony: false,
      terrace: false,
      year: [null, null],
      floor: [null, null]
    }
  },
  methods: {
    toggleSelect(select) {
      Vue.set(this.selects, select, !this.selects[select]);
    }
  }
}
</script>
