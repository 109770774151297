<template>
  <div>
    <router-view></router-view>
  </div>
</template>

<script>
import Vue from 'vue'

export default {
  name: 'catalog',
  props: [],
  components: {
  },
  data: function() {
    return {}
  },
  mounted: function(){},
  watch: {},
  methods: {}
}
</script>
