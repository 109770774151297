import Vue from 'vue'
import MainPageSearch from '../components/main_page_search.vue'
import VueRouter from 'vue-router'
import Catalog from '../components/catalog.vue'
import CatalogList from '../components/catalog_list.vue'
import CatalogOffer from '../components/catalog_offer.vue'
import CatalogUser from '../components/catalog_user.vue'
import CatalogTeam from '../components/catalog_team.vue'
import CatalogPagination from '../components/catalog_pagination.vue'
import FavoritesList from '../components/favorites_list.vue'
import underscore from 'vue-underscore'
Vue.use(underscore)
Vue.use(VueRouter)
document.addEventListener('DOMContentLoaded', () => {
  const el = document.getElementById('mainPageSearch');
  if(el) {
    var app = new Vue({
      el,
      render: h => h(MainPageSearch)
    });
  }
  const catalogOffers = document.getElementById('catalogOffers');
  if(catalogOffers) {
    const router = new VueRouter({
      mode: 'history',
      routes: [{
        path: '/:lang?/catalog',
        component: CatalogList,
        name: 'catalog',
        children: [
          {
            path: 'offer/:id',
            component: CatalogOffer,
            name: 'offer',
          },
          {
            path: 'user/:id',
            component: CatalogUser,
            name: 'user',
          },
          {
            path: 'team/:id',
            component: CatalogTeam,
            name: 'team',
          },
          {
            path: 'favorites',
            component: FavoritesList,
            name: 'favorites',
          }
        ]
      }],
    })
    window.catalog = new Vue({
      router,
      render: h => h(Catalog),
      el: '#catalogOffers',
    });
  }
  const catalogPagination = document.getElementById('catalogPagination');
  if(catalogPagination) {
    window.catalogPaginationVue = new Vue({
      el: '#catalogPagination',
      render: h => h(CatalogPagination),
    });
  }
});
