<template>
  <div class="search">
    <search-tabs v-bind:tab="tab" v-on:change-tab="changeTab"></search-tabs>
    <div class="search__content">
      <search-buy v-if="tab == 'buy'" v-bind:selection="selection" v-on:update="setSelection" v-bind:settings="settings"></search-buy>
      <search-rent v-if="tab == 'rent'" v-bind:selection="selection" v-on:update="setSelection" v-bind:settings="settings"></search-rent>
    </div>
  </div>
</template>

<script>
import Vue from 'vue';
import Cookies from 'js-cookie';
import SearchTabs from './search-tabs.vue'
import SearchBuy from './search-buy.vue'
import SearchRent from './search-rent.vue'
import SearchService from './search-service.vue'

export default {
  components: {
    SearchTabs,
    SearchBuy,
    SearchRent,
    SearchService
  },
  data: function () {
    return {
      tab: 'buy',
      selection: {},
      settings: {},
    }
  },
  created: function() {
    if(Cookies.get('search')) {
      console.log(JSON.parse(Cookies.get('search')));
      this.selection = JSON.parse(Cookies.get('search'));
      this.selection.page = 1;
      if(this.selection.offer_type == 1) {
        this.tab = 'rent';
        this.changeTab(this.tab);
      }
    }
  },
  mounted: function() {
    this.fetchSettings();
  },
  methods: {
    setSelection: function(object) {
      if(object.value === false) {
        Vue.delete(this.selection, object.type);
      } else {
        Vue.set(this.selection, object.type, object.value);
      }
    },
    fetchSettings() {
      window.axios.get('/'+window.langtag+'/api/search_settings.json')
      .then((response) => {
        this.settings = response.data;
        console.log(['settings', this.settings]);
      });
    },
    changeTab($event) {
      this.tab = $event;
      Vue.delete(this.selection, 'price');
      if(this.tab == 'buy') {
        window.move_slider(1);
      }
      if(this.tab == 'rent') {
        window.move_slider(2);
      }
    }
  }
}
</script>
