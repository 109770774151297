<template>
  <div  v-bind:key="clearkey">
    <div class="d-lg-flex">
      <div class="form-group">
        <search-select-location v-on:update="updateSelect" v-bind:selection="selection" v-on:submit="sendForm"></search-select-location>
      </div>
      <div class="form-group">
        <search-select-keys v-model="offer_types" v-on:update="updateSelect" v-bind:selection="selection"></search-select-keys>
      </div>
      <div class="form-group">
        <search-select-property-types v-on:update="updateSelect" v-bind:selection="selection"></search-select-property-types>
      </div>
      <div class="form-group">
        <search-select-price v-on:update="updateSelect" v-bind:selection="selection" v-bind:min="settings.rent_min" v-bind:max="settings.rent_max"></search-select-price>
      </div>
      <div class="form-group">
        <search-select-bedrooms v-on:update="updateSelect" v-bind:selection="selection"></search-select-bedrooms>
      </div>
      <!-- <div>
        <button type="button" class="btn btn-link btn-search" v-on:click="sendForm"></button>
      </div> -->
    </div>
    <div class="text-right">
      <a href="#" v-on:click.prevent="clearSearch" class="search__more-options-link pr-3">{{i18n('search.clear')}}</a>
      <div class="custom-control custom-checkbox" v-if="moreOptionsCount() > 0">
        <input type="checkbox" class="custom-control-input" id="clearMoreOptions" v-on:change="clearMoreOptions" checked>
        <label class="custom-control-label" for="clearMoreOptions"></label>
      </div>
      <a class="search__more-options-link" href="#" data-toggle="class" data-target=".search__more-options" data-class="search__more-options--active">
        {{i18n('search.more_options')}}
        <span v-if="moreOptionsCount() > 0">({{moreOptionsCount()}})</span>
      </a>
    </div>
    <div class="search__more-options">
      <div class="search__subtitle">
        {{i18n('search.more_options')}}
        <!-- <button type="button" class="close close--absolute" data-toggle="class" data-target=".search__more-options" data-class="search__more-options--active">
          <span aria-hidden="true">&times;</span>
        </button> -->
      </div>
      <div class="row">
        <div class="col-md-6">
          <search-more-plus-minus v-bind:label="i18n('search.salon')" name="salon" v-model="salons" min="0" max="100000" v-on:update="updateSelect" icon="icon icon--living-room"></search-more-plus-minus>
          <search-more-plus-minus v-bind:label="i18n('search.bathroom')" name="bathroom" v-model="bathrooms" min="0" max="100000" v-on:update="updateSelect" icon="icon icon--bathroom"></search-more-plus-minus>
          <search-more-plus-minus v-bind:label="i18n('search.garage')" name="garage" v-model="garages" min="0" max="100000" v-on:update="updateSelect" icon="icon icon--car" v-bind:tooltip="i18n('addad.set_amount_of_cars')"></search-more-plus-minus>
          <search-more-checkbox v-bind:label="i18n('search.pool')" name="pool" v-model="pool" v-on:update="updateSelect"></search-more-checkbox>
          <search-more-checkbox v-bind:label="i18n('search.balcony')" name="balcony" v-model="balcony" v-on:update="updateSelect"></search-more-checkbox>
        </div>
        <div class="col-md-6">
          <search-more-checkbox v-bind:label="i18n('search.terrace')" name="terrace" v-model="terrace" v-on:update="updateSelect"></search-more-checkbox>
          <search-more-checkbox v-bind:label="i18n('search.elevator')" name="elevator" v-model="elevator" v-on:update="updateSelect"></search-more-checkbox>
          <search-more-checkbox v-bind:label="i18n('search.basement')" name="basement" v-model="basement" v-on:update="updateSelect"></search-more-checkbox>
          <search-more-from-to v-bind:label="i18n('search.const_year')" name="year" v-model="year" v-on:update="updateSelect"></search-more-from-to>
          <search-more-from-to v-bind:label="i18n('search.floor')" name="floor" v-model="floor" v-on:update="updateSelect"></search-more-from-to>
        </div>
      </div>
    </div>
  </div>
</template>

<script>
import Vue from 'vue'
import SearchSelectKeys from './search-select-keys.vue'
import SearchSelectPropertyTypes from './search-select-property-types.vue'
import SearchSelectLocation from './search-select-location.vue'
import SearchSelectPrice from './search-select-price.vue'
import SearchSelectBedrooms from './search-select-bedrooms.vue'
import SearchMorePlusMinus from './search-more-plus-minus.vue'
import SearchMoreCheckbox from './search-more-checkbox.vue'
import SearchMoreFromTo from './search-more-from-to.vue'
import i18nmixin from '../customs/i18nmixin'
import queryString from 'query-string'

export default {
  name: 'search-rent',
  mixins: [i18nmixin],
  props: ['selection', 'settings'],
  components: {
    SearchSelectKeys,
    SearchSelectPropertyTypes,
    SearchSelectLocation,
    SearchSelectPrice,
    SearchSelectBedrooms,
    SearchMorePlusMinus,
    SearchMoreCheckbox,
    SearchMoreFromTo
  },
  data: function() {
    return {
      offer_types: [
        {
          label: this.i18n('accounts.developer'),
          name: 'developer',
          selected: true,
        },
        {
          label: this.i18n('accounts.agency'),
          name: 'agency',
          selected: true,
        },
        {
          label: this.i18n('accounts.owner'),
          name: 'owner',
          selected: true,
        },
        {
          label: this.i18n('accounts.observer'),
          name: 'observer',
          selected: true,
        }
      ],
      salons: 0,
      bathrooms: 0,
      garages: 0,
      pool: false,
      balcony: false,
      terrace: false,
      basement: false,
      elevator: false,
      year: [null, null],
      floor: [null, null],
      clearkey: 'clear_0',
    }
  },
  mounted: function() {
    if(this.selection.salon) {
      this.salons = this.selection.salon;
    }
    if(this.selection.bathroom) {
      this.bathrooms = this.selection.bathroom;
    }
    if(this.selection.garage) {
      this.garages = this.selection.garage;
    }
    if(this.selection.pool) {
      this.pool = true;
    }
    if(this.selection.balcony) {
      this.balcony = true;
    }
    if(this.selection.terrace) {
      this.terrace = true;
    }
    if(this.selection.basement) {
      this.basement = true;
    }
    if(this.selection.elevator) {
      this.elevator = true;
    }
    if(this.selection.year) {
      this.year = this.selection.year;
    }
    if(this.selection.floor) {
      this.floor = this.selection.floor;
    }
    this.updateSelect({type: 'offer_type', value: "1"});
  },
  watch: {
    selection: function(val) {
      if(val.salon) {
        this.salons = val.salon;
      } else {
        this.salons = 0;
      }
      if(val.bathroom) {
        this.bathrooms = val.bathroom;
      } else {
        this.bathrooms = 0;
      }
      if(val.garage) {
        this.garages = val.garage;
      } else {
        this.garages = 0;
      }
      if(val.pool) {
        this.pool = true;
      } else {
        this.pool = false;
      }
      if(val.balcony) {
        this.balcony = true;
      }else {
        this.balcony = false;
      }
      if(val.terrace) {
        this.terrace = true;
      }else {
        this.terrace = false;
      }
      if(val.basement) {
        this.basement = true;
      }else {
        this.basement = false;
      }
      if(val.elevator) {
        this.elevator = true;
      }else {
        this.elevator = false;
      }
      if(val.year) {
        this.year = val.year;
      } else {
        this.year = [null, null];
      }
      if(val.floor) {
        this.floor = val.floor;
      } else {
        this.floor = [null, null];
      }
    }
  },
  methods: {
    moreOptionsCount() {
      var count = 0;
      if(this.selection.salon) {
        count++;
      }
      if(this.selection.bathroom) {
        count++;
      }
      if(this.selection.garage) {
        count++;
      }
      if(this.selection.pool) {
        count++;
      }
      if(this.selection.balcony) {
        count++;
      }
      if(this.selection.terrace) {
        count++;
      }
      if(this.selection.basement) {
        count++;
      }
      if(this.selection.elevator) {
        count++;
      }
      if(this.selection.year) {
        count++;
      }
      if(this.selection.floor) {
        count++;
      }
      return count;
    },
    clearMoreOptions() {
      if(this.selection.salon) {
        this.updateSelect({type: 'salon', value: false});
      }
      if(this.selection.bathroom) {
        this.updateSelect({type: 'bathroom', value: false});
      }
      if(this.selection.garage) {
        this.updateSelect({type: 'garage', value: false});
      }
      if(this.selection.pool) {
        this.updateSelect({type: 'pool', value: false});
      }
      if(this.selection.balcony) {
        this.updateSelect({type: 'balcony', value: false});
      }
      if(this.selection.terrace) {
        this.updateSelect({type: 'terrace', value: false});
      }
      if(this.selection.basement) {
        this.updateSelect({type: 'basement', value: false});
      }
      if(this.selection.elevator) {
        this.updateSelect({type: 'elevator', value: false});
      }
      if(this.selection.year) {
        this.updateSelect({type: 'year', value: false});
      }
      if(this.selection.floor) {
        this.updateSelect({type: 'floor', value: false});
      }
    },
    clearSearch() {
      for(var key in this.selection) {
        this.updateSelect({type: key, value: false});
      }
      this.updateSelect({type: 'offer_type', value: "1"});
      this.clearkey = 'clear_'+Math.floor(Date.now() / 1000);
    },
    toggleSelect(select) {
      Vue.set(this.selects, select, !this.selects[select]);
    },
    updateSelect(object) {
      this.$emit('update', object);
    },
    sendForm() {
      this.$emit('submit');
    }
  }
}
</script>
