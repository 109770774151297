<template>
  <div class="select select--location select--empty">
    <div class="select__control">
      <div class="select__icon select__icon--localisation"></div>
      <div class="select__content select__content--has-inner">
        <div class="select__content__input select__open-n">
          <span class="ellipsis">
            <span v-if="address || selected_place">{{selected_place ? selected_place.description : address}}</span>
            <span v-else class="text-muted">{{i18n('search.address_placeholder')}}</span>
          </span>
          <span v-if="distance > 0 && selected_place">
            +{{distance}} {{unit}}
          </span>
        </div>
        <input type="text" v-bind:placeholder="i18n('search.address_placeholder')" v-model="address" v-on:keyup="sendIfEnter" class="select__content__input select__open-d">
      </div>
      <div class="select__arrow select__arrow--search"></div>
    </div>
    <div class="select__dropdown">
      <div class="d-flex">
        <div class="select__dropdown__body select__dropdown__body--large-column">
          <!-- <div class="select__label">{{i18n('search.search_results')}}</div> -->
          <div class="mb-2">
            <div v-if="this.locations.length == 0">
              <div class="text-muted">
                <i v-if="type == 'start'">{{i18n('search.start_typing')}}</i>
                <i v-if="type == 'waiting'">{{i18n('search.wait')}}</i>
                <i v-if="type == 'success'">{{i18n('search.no_results')}}</i>
              </div>
            </div>
            <ul v-else class="list-unstyled">
              <li  v-for="location in this.locations" class="search__result-row" v-on:click="sendLocation(location.place_id)">
                <div>{{location.description.split(',')[0]}}</div>
                <div class="offer-type-checkbox__label__small text-muted">{{location.description.split(',').slice(1).join(',')}}</div>
              </li>
            </ul>
          </div>
          <div v-if="displayHistory()">
            <div class="select__label">{{i18n('search.history')}}</div>
            <ul class="list-unstyled">
              <li class="search__result-row" v-for="location in this.last_locations" v-on:click="sendLocation(location.place_id)" v-if="!inCurrentList(location)">
                <div>{{location.description.split(',')[0]}}</div>
                <div class="offer-type-checkbox__label__small text-muted">{{location.description.split(',').slice(1).join(',')}}</div>
              </li>
            </ul>
          </div>
        </div>

        <div class="select__dropdown__body select__dropdown__body--small-column">
          <div v-if="selected_place">
            <div class="select__label">{{i18n('search.distance')}}</div>
            <vue-slider v-model="distance"  v-bind:data="distances" dotSize="20" :height="150" direction="btt" :tooltip="'always'" :tooltip-placement="'right'" :tooltip-formatter="distanceFormater"></vue-slider>
          </div>
        </div>
      </div>
      <div class="select__dropdown__footer">
        <div class="row">
          <div class="col-5">
            <!-- <a href="#" v-on:click.prevent="clear">{{i18n('search.clear')}}</a>
            |
            <a href="#" v-on:click.prevent="clearHistory">Clear history</a> -->
          </div>
          <div class="col-7 text-right">
            <!-- <img src="../assets/images/powered_by_google_on_white.png" alt="Powered by Google" /> -->
            <small>© <a href="https://osm.org/copyright">OpenStreetMap</a> contributors</small>
          </div>
        </div>
      </div>
    </div>
  </div>
</template>
make_slug
<script>
import Vue from 'vue'
import add_maps from '../customs/add_maps';
import SearchPlaces from '../customs/search_places';
import Cookies from 'js-cookie';
import VueSlider from 'vue-slider-component';
import createChannel from "../customs/cable";
import i18nmixin from '../customs/i18nmixin';
import ES6Promise from "es6-promise";
ES6Promise.polyfill();
import $ from "jquery";

export default {
  name: 'search-select-location',
  mixins: [i18nmixin],
  props: ['selection'],
  components: {
    VueSlider
  },
  data: function() {
    return {
      last_locations: [],
      locations: [],
      distance: 0,
      address: '',
      selected_place: null,
      selected_place_key: null,
      geocoder: null,
      autocomplete_timeout: null,
      selected_props: [],
      history_version: 0,
      type: 'start',
      unit: 'km',
      distances: [
        0, 5, 10, 15, 25, 50, 75
      ],
      distanceFormater: function(v) {
        return v+window.full_unit.k;
      }
    }
  },
  mounted: function(){
    this.unit = window.full_unit.k;
    this.geocoder = new SearchPlaces();
    this.last_locations = JSON.parse(Cookies.get('last_locations') || "[]") || [];
    this.createChannel();
    if(this.selection.place) {
      this.sendLocation(this.selection.place);
      if(!this.selected_place) {
        this.$emit('update', {type: 'place', value: false});
      }
    }
    if(this.selection.distance) {
      this.distance = parseInt(this.selection.distance);
    }
    if(this.selection.address) {
      this.address = this.selection.address;
      this.findAddress();
    }
  },
  watch: {
    address: function(val) {
      if(val.length == 0) {
        this.type = 'start';
        clearTimeout(this.autocomplete_timeout);
        this.locations = [];
        this.$emit('update', {type: 'address', value: false});
      } else {
        this.findAddress();
      }
    },
    distance: function(val) {
      this.$emit('update', {type: 'distance', value: val});
    }
  },
  methods: {
    sendLocation(val) {
      for(var i = 0; i < this.locations.length; i++) {
        if(this.locations[i].place_id == val) {
          this.selected_place = this.locations[i];
          setTimeout(this.addToHistory, 10);
          return;
        }
      }
      for(var i = 0; i < this.last_locations.length; i++) {
        if(this.last_locations[i].place_id == val) {
          this.selected_place = this.last_locations[i];
          setTimeout(this.addToHistory, 10);
          return;
        }
      }
    },
    findAddress() {
      clearTimeout(this.autocomplete_timeout);
      this.autocomplete_timeout = setTimeout(() => {
        this.geocoder.searchPlaces(this.address, (locations, type) => {
          this.locations = locations;
          this.type = type;
        });
      }, 250);
    },
    inCurrentList(location) {
      for(var i = 0; i < this.locations.length; i++) {
        if(this.locations[i].place_id == location.place_id) {
          return true;
        }
      }
      return false;
    },
    addToHistory() {
      this.$emit('update', {type: 'place', value: this.selected_place.place_id});
      this.$emit('update', {type: 'address', value: false});
      this.address = this.selected_place.description;
      $('.select--location').removeClass('select--active');
      for(var i = 0; i < this.last_locations.length; i++) {
        if(this.last_locations[i].place_id == this.selected_place.place_id) {
          Vue.delete(this.last_locations, i);
        }
        if(i > 3) {
          Vue.delete(this.last_locations, i);
        }
      }
      this.last_locations.unshift(this.selected_place);
      this.history_version = this.history_version+1;
      Cookies.set('last_locations', JSON.stringify(this.last_locations));
    },
    displayHistory() {
      var display = false;
      for(var i = 0; i < this.last_locations.length; i++) {
        display = display || !this.inCurrentList(this.last_locations[i]);
      }
      return display;
    },
    clearHistory() {
      Cookies.set('last_locations', JSON.stringify([]));
      this.last_locations = [];
    },
    clear() {
      this.selected_place = null;
      this.selected_place_key = null;
      this.address = '';
      this.distance = 0;
      this.$emit('update', {type: 'place', value: false});
      this.$emit('update', {type: 'distance', value: false});
      this.$emit('update', {type: 'address', value: false});
    },
    sendIfEnter(e) {
        if(e.keyCode == 8) {
            console.log('clearPlace');
          this.$emit('update', {type: 'place', value: false});
        }
        this.selected_place = null;
        if(this.address.length > 0) {
          this.$emit('update', {type: 'address', value: this.address});
          this.$emit('update', {type: 'place', value: false});
        }
        if(e.keyCode == 13) {
            this.selected_place = null;
            if(this.address.length > 0) {
              this.$emit('update', {type: 'address', value: this.address});
            } else {
              this.$emit('update', {type: 'address', value: false});
            }
            this.$emit('update', {type: 'place', value: false});
            this.$emit('submit', true);
            $('.select--active').removeClass('select--active');
        }
    },
    createChannel() {
      var _self = this;
      createChannel("WebLocationNotificationsChannel", {
        received(data) {
          if(data.q && data.langtag == window.langtag && data.q.toLowerCase() == _self.address.toLowerCase()) {
            _self.findAddress();
          }
        }
      });
    }
  }
}
</script>
