<template>
  <div class="select select--empty">
    <div class="select__control">
      <div class="select__icon select__icon--house"></div>
      <div class="select__content" v-bind:key="desc_key">
        {{description()}}
      </div>
      <div class="select__arrow"></div>
    </div>
    <div class="select__dropdown">
      <div class="select__dropdown__body">
        <div class="row">
          <div class="col-md-6" v-for="property_type in property_types">
            <div class="offer-type-checkbox">
              <div class="offer-type-checkbox__checkbox">
                <div class="custom-control custom-checkbox">
                  <input type="checkbox" class="custom-control-input" v-model="property_type.checked" v-bind:id="'searchPropertyCheckbox-'+property_type.slug" v-on:change="checkAllTypes"  v-bind:disabled="disable_types && property_type.checked">
                  <label class="custom-control-label" v-bind:for="'searchPropertyCheckbox-'+property_type.slug"></label>
                </div>
              </div>
              <label class="offer-type-checkbox__label" v-bind:for="'searchPropertyCheckbox-'+property_type.slug">{{property_type.title}}</label>
            </div>
          </div>
        </div>
      </div>

      <div class="select__dropdown__footer" v-if="detailedPropertyTypes().length > 1"  v-bind:key="key">
        <div class="row">
          <div class="col-md-6" v-for="col in $_.chunk(detailedPropertyTypes(), detailedPropertyTypes().length/2.0)">
            <div class="offer-type-checkbox" v-for="property_type in col">
              <div class="offer-type-checkbox__checkbox">
                <div class="custom-control custom-checkbox">
                  <input type="checkbox" class="custom-control-input" v-model="property_type.checked" v-bind:id="'searchPropertyCheckbox-'+property_type.slug" v-on:change="changedDetailedType(property_type.id)" v-bind:disabled="disable_subtypes && property_type.checked">
                  <label class="custom-control-label" v-bind:for="'searchPropertyCheckbox-'+property_type.slug"></label>
                </div>
              </div>
              <label class="offer-type-checkbox__label" v-bind:for="'searchPropertyCheckbox-'+property_type.slug">{{property_type.title}}</label>
            </div>
          </div>
        </div>
        <!-- <div class="offer-type-checkbox mb-0">
          <div class="offer-type-checkbox__checkbox">
            <div class="custom-control custom-checkbox">
              <input type="checkbox" class="custom-control-input" v-model="all_types" id="checkAllTypes" v-on:change="selectAllTypes">
              <label class="custom-control-label" for="checkAllTypes"></label>
            </div>
          </div>
          <label class="offer-type-checkbox__label" for="checkAllTypes">{{i18n('search.all_types')}}</label>
        </div> -->
      </div>
    </div>
  </div>
</template>
<script>
import Vue from 'vue'
import i18nmixin from '../customs/i18nmixin'

export default {
  name: 'search-select-keys',
  props: ['active', 'selection'],
  mixins: [i18nmixin],
  data: function() {
    return {
      property_types: [],
      all_types: true,
      keyVal: 0,
      key: 'details_0',
      desc_key: 'desc_0',
      disable_types: false,
      disable_subtypes: false,
    }
  },
  mounted: function(){
    this.fetchTypes();
  },
  methods: {
    fetchTypes() {
      window.axios.get('/'+window.langtag+'/api/property_type_groups')
      .then((response) => {
        if(this.selection.property_types) {
          this.property_types = [];
          var selectedPropertyTypes = this.selection.property_types;
          if(!Array.isArray(selectedPropertyTypes)) {
            selectedPropertyTypes = [selectedPropertyTypes];
          }
          for(var i = 0; i < response.data.length; i++) {
            var tmp = response.data[i];
            if(selectedPropertyTypes.indexOf(tmp.slug) != -1) {
              tmp.checked = true;
              if(this.selection.detailed_pt) {
                for(var j = 0; j < tmp.property_types.length; j++) {
                  tmp.property_types[j].checked = true;
                }
              }
            }
            this.property_types.push(tmp);
          }
        } else if (this.selection.detailed_pt) {
          this.property_types = [];
          var selectedDetailed = this.selection.detailed_pt;
          if(!Array.isArray(selectedDetailed)) {
            selectedDetailed = [selectedDetailed];
          }
          for(var i = 0; i < response.data.length; i++) {
            var tmp = response.data[i];
            for(var j = 0; j < tmp.property_types.length; j++) {
              if(selectedDetailed.indexOf(tmp.property_types[j].slug) != -1) {
                console.log(['select '+tmp.property_types[j].slug, selectedDetailed]);
                tmp.property_types[j].checked = true;
                tmp.checked = true;
              }
            }
            this.property_types.push(tmp);
          }
        } else {
          this.property_types = response.data;
        }
        this.checkIfNone();
        if(this.selectedPropertyTypes().length == 1) {
          this.disable_types = true;
        } else {
          this.disable_types = false;
        }
      })
    },
    selectAllTypes() {
      for(var i = 0; i < this.property_types.length; i++) {
        Vue.set(this.property_types[i], 'checked', false);
      }
    },
    propertyTypesList() {
      var propertyTypeNames = [];
      for(var i = 0; i < this.property_types.length; i++) {
        if(this.property_types[i].checked) {
          propertyTypeNames.push(this.property_types[i].title);
        }
      }
      return propertyTypeNames.join(', ')
    },
    checkAllTypes(emit = true) {
      if(this.selectedPropertyTypes().length == 1) {
          for(var i = 0; i < this.property_types.length; i++) {
            if(this.property_types[i].checked) {
              this.property_types[i].property_types
              for(var j = 0; j < this.property_types[i].property_types.length; j++) {
                  this.property_types[i].property_types[j].checked = true;
              }
            }
          }
          this.disable_types = true;
      } else {
        this.disable_types = false;
      }
      if(emit) {
        this.emitUpdate();
      }
    },
    checkIfNone() {
      if(this.selectedPropertyTypes() == 0) {
        for(var i = 0; i < this.property_types.length; i++) {
          Vue.set(this.property_types[i], 'checked', true);
        }
      }
    },
    anySelected() {
      var any_selected = false;
      for(var i = 0; i < this.property_types.length; i++) {
        if(this.property_types[i].checked) {
          any_selected = true;
          break;
        }
      }
      return any_selected;
    },
    selectedPropertyTypes() {
      var propertyTypes = [];
      for(var i = 0; i < this.property_types.length; i++) {
        if(this.property_types[i].checked) {
          propertyTypes.push(this.property_types[i]);
        }
      }
      return propertyTypes;
    },
    detailedPropertyTypes() {
      var detailedPropertyTypes = [];
      if(this.selectedPropertyTypes().length == 1) {
        detailedPropertyTypes = this.selectedPropertyTypes()[0].property_types;
      }
      return detailedPropertyTypes;
    },
    changedDetailedType(detailed_pt) {
      for(var i = 0; i < this.property_types.length; i++) {
        if(this.property_types[i].checked) {
          var checked = 0;
          for(var j = 0; j < this.property_types[i].property_types.length; j++) {
            if(this.property_types[i].property_types[j].checked) {
              checked++;
            }
          }
          if(checked == 1) {
            this.disable_subtypes = true;
            // this.checkAllTypes();
          } else {
            this.disable_subtypes = false;
          }
        }
      }
      var self = this;
      Vue.nextTick(function () {
        self.checkIfNone();
        self.keyVal++;
        self.key = 'details_'+self.keyVal;
        self.desc_key = 'desc_'+self.keyVal;
      });
      this.emitUpdate();
    },
    description() {
      if(this.anySelected()) {
        if(this.property_types.length == this.selectedPropertyTypes().length) {
          return this.i18n('search.property_type');
        }
        if(this.selectedPropertyTypes().length == 1) {
          var tmp = this.selectedPropertyTypes()[0];
          var selectedDetailsNames = [];
          for(var j = 0; j < tmp.property_types.length; j++) {
            if(tmp.property_types[j].checked) {
              selectedDetailsNames.push(tmp.property_types[j].title);
            }
          }
          if(selectedDetailsNames.length > 0 && selectedDetailsNames.length < tmp.property_types.length) {
            return selectedDetailsNames.join(', ');
          }
        }
        return this.propertyTypesList()
      } else {
        return this.i18n('search.property_type');
      }
    },
    emitUpdate() {
      var selectedPropertyTypes = [];
      var selectedPropertyTypesFull = [];
      for(var i = 0; i < this.property_types.length; i++) {
        if(this.property_types[i].checked) {
          selectedPropertyTypes.push(this.property_types[i].slug);
          selectedPropertyTypesFull.push(this.property_types[i])
        }
      }
      if(selectedPropertyTypesFull.length == 1) {
        var totalDetailedPropertyTypes = selectedPropertyTypesFull[0].property_types.length;
        var selectedDetailedPropertyTypes = 0;
        var detailedPropertyTypes = [];
        for(var j = 0; j < selectedPropertyTypesFull[0].property_types.length; j++) {
          if(selectedPropertyTypesFull[0].property_types[j].checked) {
            detailedPropertyTypes.push(selectedPropertyTypesFull[0].property_types[j].slug);
          }
        }
        selectedDetailedPropertyTypes = detailedPropertyTypes.length;
        if(selectedDetailedPropertyTypes == totalDetailedPropertyTypes || selectedDetailedPropertyTypes == 0) {
          this.$emit('update', {type: 'property_types', value: selectedPropertyTypes});
          this.$emit('update', {type: 'detailed_pt', value: false});
        } else {
          this.$emit('update', {type: 'detailed_pt', value: detailedPropertyTypes});
          this.$emit('update', {type: 'property_types', value: false});
        }
      } else if (this.property_types.length == selectedPropertyTypes.length) {
        this.$emit('update', {type: 'property_types', value: false});
        this.$emit('update', {type: 'detailed_pt', value: false});
      }else {
        this.$emit('update', {type: 'property_types', value: selectedPropertyTypes});
        this.$emit('update', {type: 'detailed_pt', value: false});
      }
      // this.$emit('update', {type: 'property_types', value: selectedPropertyTypes});
    }
  }
}
</script>
