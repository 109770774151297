<template>
  <span>
    <span v-if="field.icon" class="offer__field__icon" v-bind:style="{backgroundImage: 'url(\''+field.icon+'\')'}" v-bind:title="field.title"></span>
    <span v-else>
      <span v-if="field.list_value">{{field.title}}:</span>
      <span v-else>{{field.title}}</span>
    </span>
    <span v-if="field.list_value">{{field.list_value}} {{i18n('offer.cars')}}</span>
  </span>
</template>

<script>
import Vue from 'vue'
import i18nmixin from '../../customs/i18nmixin'

export default {
  mixins: [i18nmixin],
  components: {},
  props: ['field'],
  methods: {}
}
</script>
