export { default as offerFieldsPrice } from './fields-price.vue'
export { default as offerFieldsArea } from './fields-area.vue'
export { default as offerFieldsCheckbox } from './fields-checkbox.vue'
export { default as offerFieldsAmountWithSize} from './fields-amount-with-size.vue'
export { default as offerFieldsGarage} from './fields-garage.vue'
export { default as offerFieldsNumber} from './fields-number.vue'
export { default as offerFieldsStorey} from './fields-storey.vue'
export { default as offerFieldsCheckboxWithSize} from './fields-checkbox-with-size.vue'

export { default as offerListFieldsPrice } from './list-fields-price.vue'
export { default as offerListFieldsArea } from './list-fields-area.vue'
export { default as offerListFieldsCheckbox } from './list-fields-checkbox.vue'
export { default as offerListFieldsAmountWithSize} from './list-fields-amount-with-size.vue'
export { default as offerListFieldsGarage} from './list-fields-garage.vue'
export { default as offerListFieldsNumber} from './list-fields-number.vue'
export { default as offerListFieldsStorey} from './list-fields-storey.vue'
export { default as offerListFieldsCheckboxWithSize} from './list-fields-checkbox-with-size.vue'
