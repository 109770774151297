<template>
  <div class="search__form-group">
    <div class="row">
      <div class="col-7">
        <label v-if="tooltip" data-toggle="tooltip" :title="tooltip">{{label}}</label>
        <label v-else>{{label}}</label>
        <span v-if="icon">
          <i v-for="n in Math.min(4, this.model)" v-bind:class="icon"></i>
          <b v-if="this.model > 4">+</b>
        </span>
      </div>
      <div class="col-5 text-right">
        <button type="button" class="btn btn-minus" v-on:click="sub">-</button>
        <button type="button" class="btn btn-plus" v-on:click="add">+</button>
        <input type="text" class="form-control search__plussub__input" v-model="model" v-bind:name="name" readonly v-on:change="checkValue()"/>
      </div>
    </div>
  </div>
</template>

<script>
import Vue from 'vue'
import i18nmixin from '../customs/i18nmixin'

export default {
  name: 'search-more-plus-minus',
  props: ['label', 'name', 'min', 'max', 'value', 'icon', 'tooltip'],
  mixins: [i18nmixin],
  components: {
  },
  data: function() {
    return {
      model: 0
    }
  },
  mounted: function(){
    this.model = this.value;
    if(this.tooltip) {
      Vue.nextTick(function(){
        window.$('[data-toggle="tooltip"]').tooltip({ boundary: 'window' });
      });
    }
  },
  watch: {
    value: function(val) {
      this.model = parseInt(val);
    }
  },
  methods: {
    add() {
      this.model = this.model+1;
      this.checkValue();
    },
    sub() {
      this.model = this.model-1;
      this.checkValue();
    },
    checkValue: function() {
      this.model = Math.min(this.max, Math.max(this.min, (parseInt(this.model) || 0)));
      this.$emit('input', this.model);
      if(this.model == this.min) {
        this.$emit('update', {type: this.name, value: false});
      } else {
        this.$emit('update', {type: this.name, value: this.model});
      }
    }
  }
}
</script>
