<template>
  <nav class="catalog__pagination">
    <ul  v-if="meta.total_pages > 1" class="pagination pagination-sm mb-0">
      <li class="page-item" aria-current="page" v-if="meta.current_page > 1">
        <a class="page-link" v-on:click.prevent="goToPage(1)" href="#">1</a>
      </li>
      <li class="page-item" aria-current="page" v-if="meta.current_page > 4">
        <span class="page-link">
          ...
        </span>
      </li>
      <li class="page-item" aria-current="page" v-for="n in prevPageCount()">
        <a class="page-link" v-on:click.prevent="goToPage(meta.current_page-(prevPageCount()-n)-1)" href="#">{{meta.current_page-(prevPageCount()-n)-1}}</a>
      </li>
      <li class="page-item active" aria-current="page">
        <span class="page-link">
          {{meta.current_page}}
        </span>
      </li>
      <li class="page-item" aria-current="page" v-for="n in (Math.min(meta.total_pages-meta.current_page, 2))">
        <a class="page-link" v-on:click.prevent="goToPage(meta.current_page+n)" href="#">{{meta.current_page+n}}</a>
      </li>
      <li class="page-item" aria-current="page" v-if="meta.current_page < meta.total_pages-3">
        <span class="page-link">
          ...
        </span>
      </li>
      <li class="page-item" aria-current="page" v-if="meta.current_page < meta.total_pages-2">
        <a class="page-link" v-on:click.prevent="goToPage(meta.total_pages)" href="#">{{meta.total_pages}}</a>
      </li>
    </ul>
  </nav>
</template>

<script>
import Vue from 'vue'
import lazyLoad from '../customs/lazy_load';
import CatalogListSingle from './catalog_list_single';
import Search from './catalog-search';
import Map from 'ol/Map';
import View from 'ol/View';
import TileLayer from 'ol/layer/Tile';
import Feature from 'ol/Feature';
import Overlay from 'ol/Overlay';
import Point from 'ol/geom/Point';
import OSM from 'ol/source/OSM';
import Vector from 'ol/source/Vector';
import VectorLayer from 'ol/layer/Vector';
import {fromLonLat} from 'ol/proj';
import {Style, Icon} from 'ol/style';
import markerImage from '../assets/images/marker.svg';

export default {
  name: 'catalog-pagination',
  props: [],
  components: {

  },
  data: function() {
    return {
      meta: {
        total_pages: 0,
        current_page: 0,
      }
    }
  },
  mounted: function() {
  },
  watch: {},
  methods: {
    updateMeta(meta) {
      this.meta = meta;
    },
    prevPageCount() {
      return Math.max(Math.min(this.meta.current_page-2, 2), 0);
    },
    goToPage(page) {
      window.catalog.$children[0].$children[0].goToPage(page);
    },
  }
}
</script>
