import 'intersection-observer';
import uuidv1 from 'uuid/v1';
import $ from "jquery";

export default function(root) {
  if(!root) {
    root = document;
  }
  //Lazy background
  IntersectionObserver.prototype.POLL_INTERVAL = 1000;
  var lazyBackgrounds = $(root).find(".lazy-background").toArray();
  var io = new IntersectionObserver(function(entries, observer) {
    entries.forEach(function(entry) {
      if (entry.isIntersecting) {
        // // Check ID
        // if(!entry.target.id) {
        //   entry.target.id = 'lazy-'+uuidv1();
        // }
        // // Generate style
        // var css = '#'+entry.target.id+' { background-image: url("'+entry.target.dataset.src+'")!important; }';
        // // Add style
        // var head = document.head || document.getElementsByTagName('head')[0];
        // if(!document.getElementById('lazy-style')) {
        //   var style = document.createElement('style');
        //   style.id = 'lazy-style';
        //   style.type = 'text/css';
        //   head.appendChild(style);
        // }
        // var lazyStyle = document.getElementById('lazy-style');
        // if (lazyStyle.styleSheet){
        //   // This is required for IE8 and below.
        //   style.styleSheet.cssText = style.styleSheet.cssText+"\n"+css;
        // } else {
        //   lazyStyle.appendChild(document.createTextNode(css));
        // }
        $('<img/>').attr('src', entry.target.dataset.src).on('load', function() {
           $(this).remove(); // prevent memory leaks as @benweet suggested
           $(entry.target).css('background-image', 'url(\''+entry.target.dataset.src+'\')');
           $(entry.target).addClass("lazy-background--loaded");
        });
        io.unobserve(entry.target);
      }
    });
  });
  lazyBackgrounds.forEach(function(lazyImage) {
    io.observe(lazyImage);
  });
}
