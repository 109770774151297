<template>
  <div>
    <div class="offer" v-on:click.self="close">
      <div class="offer__inner container">
        <button type="button" v-on:click.prevent="close" class="close close--absolute"><span aria-hidden="true">&times;</span></button>
        <div v-if="team">
          <div class="offer__profile__personal">
            <router-link :to="{name: 'team', params: {lang: lang, id: team.id}}" class="offer__profile__team-icon" v-bind:style="userBackground(team)"></router-link>
            <router-link :to="{name: 'team', params: {lang: lang, id: team.id}}">{{team.name}}</router-link>
            <br />
            <span v-if="team.licence_number">{{i18n('offer.licence_number')}}: {{team.licence_number}}</span>
          </div>
        </div>
        <div class="clearfix"></div>
        <div v-if="loading" class="lds-dual-ring"></div>
        <div v-else class="catalog__list">
          <catalog-list-single :key="offer.id" v-for="offer in offers" v-bind:offer="offer"></catalog-list-single>
          <div class="alert alert-info mt-2" v-if="offers.length == 0">{{i18n('search.no_results')}}</div>
        </div>
        <nav class="pagination justify-content-center">
          <ul  v-if="meta.total_pages > 1" class="pagination pagination-sm mb-0">
            <li class="page-item" aria-current="page" v-if="meta.current_page > 1">
              <a class="page-link" v-on:click.prevent="goToPage(1)" href="#">1</a>
            </li>
            <li class="page-item" aria-current="page" v-if="meta.current_page > 4">
              <span class="page-link">
                ...
              </span>
            </li>
            <li class="page-item" aria-current="page" v-for="n in prevPageCount()">
              <a class="page-link" v-on:click.prevent="goToPage(meta.current_page-(prevPageCount()-n)-1)" href="#">{{meta.current_page-(prevPageCount()-n)-1}}</a>
            </li>
            <li class="page-item active" aria-current="page">
              <span class="page-link">
                {{meta.current_page}}
              </span>
            </li>
            <li class="page-item" aria-current="page" v-for="n in (Math.min(meta.total_pages-meta.current_page, 2))">
              <a class="page-link" v-on:click.prevent="goToPage(meta.current_page+n)" href="#">{{meta.current_page+n}}</a>
            </li>
            <li class="page-item" aria-current="page" v-if="meta.current_page < meta.total_pages-3">
              <span class="page-link">
                ...
              </span>
            </li>
            <li class="page-item" aria-current="page" v-if="meta.current_page < meta.total_pages-2">
              <a class="page-link" v-on:click.prevent="goToPage(meta.total_pages)" href="#">{{meta.total_pages}}</a>
            </li>
          </ul>
        </nav>
      </div>
    </div>
  </div>
</template>

<script>
import Vue from 'vue'
import lazyLoad from '../customs/lazy_load';
import CatalogListSingle from './catalog_list_single';
import $ from "jquery";
import 'bootstrap/js/dist/modal';
import i18nmixin from '../customs/i18nmixin'
import markerImage from '../assets/images/marker.svg';
import Clock from './clock';
import * as components from './offer_fields'

export default {
  name: 'catalog-team',
  props: ['id'],
  mixins: [i18nmixin],
  components: {
    CatalogListSingle,
    ...components
  },
  data: function() {
    return {
      offers: [],
      is_back: false,
      lang: window.langtag,
      team: null,
      loading: false,
      meta: {
        total_pages: 0,
        current_page: 0,
      },
    }
  },
  mounted: function() {
    $('body').addClass('offer-open');
    this.fetchOffers();
  },
  watch: {},
  beforeRouteEnter: function(to, from, next) {
    next(vm => {
      vm.is_back = true;
    });
  },
  methods: {
    fetchOffers: function(page = 1) {
      var self = this;
      this.loading = true;
      window.axios.get(window.langtag_path+'/api/catalog/team/'+this.$route.params.id+'.json', {
        params: {page: page}
      })
      .then((response) => {
        this.offers = response.data.data;
        this.team = response.data.team;
        this.meta = response.data.meta;
        this.loading = false;
        setTimeout(function() {
          lazyLoad('.catalog__list');
        }, 0);
      })
    },
    userBackground(user) {
      if(user.photo_url) {
        return {'background-image': 'url(\''+user.photo_url+'\')'};
      }
    },
    close: function() {
      $('body').removeClass('offer-open');
      if(this.is_back) {
        this.$router.back();
      } else {
        this.$router.push({name: 'catalog', params: {lang: this.lang}, query: { offer_type: 0 }});
      }
    },
    prevPageCount() {
      return Math.max(Math.min(this.meta.current_page-2, 2), 0);
    },
    goToPage(page) {
      this.fetchOffers(page);
    },
  }
}
</script>
