<template>
  <div>
    <div class="offer" v-on:click.self="close">
      <div class="offer__inner offer__inner--favorites">
        <button type="button" v-on:click.prevent="close" class="close close--absolute"><span aria-hidden="true">&times;</span></button>
        <h2>{{i18n('favorites.title')}}</h2>
        <div class="clearfix"></div>
        <ul class="list-unstyled">
          <li class="favorites__row" v-for="offer in offers">
            <figure class="lazy-background favorites__image" v-bind:data-src="photoUrl(offer)" v-bind:style="photoBackground(offer)" v-on:click="openOffer(offer.id)"></figure>
            <div class="favorites__user" v-bind:class="['favorites__user--'+offer.user.user_type.type]" v-bind:style="userBackground(offer.user)"></div>
            <div class="favorites__address" v-on:click="openOffer(offer.id)">
              <strong class="catalog__ribbon">{{offer.ribbon}}</strong>
              <small>{{offer.ad_addresses[0].place.address_name}}</small>
              <div class="catalog__fields">
                <div class="catalog__field" v-if="field.name != 'Price' && field.on_list && field.active && field.icon" v-for="field in offer.ad_fields">
                  <component :is="'offerList'+field.type.replace('::', '')" :field="field"></component>
                </div>
              </div>
            </div>
            <div class="favorites__price">
              <div>
                {{offer.price}}
              </div>
              <small><span v-if="!offer.in_local_currency">{{offer.local_price}}</span><span v-else>&nbsp;</span></small>
            </div>
            <button class="btn favorites__remove" type="button" v-on:click="remove(offer)"><i class="fas fa-trash"></i></button>
          </li>
        </ul>
        <div class="alert alert-info mt-2" v-if="offers.length == 0">{{i18n('search.no_results')}}</div>
      </div>
    </div>
  </div>
</template>

<script>
import Vue from 'vue'
import lazyLoad from '../customs/lazy_load';
import CatalogListSingle from './catalog_list_single';
import $ from "jquery";
import 'bootstrap/js/dist/modal';
import i18nmixin from '../customs/i18nmixin'
import markerImage from '../assets/images/marker.svg';
import Clock from './clock';
import * as components from './offer_fields'
import createChannel from "../customs/cable"

export default {
  name: 'favorites-list',
  props: [],
  mixins: [i18nmixin],
  components: {
    CatalogListSingle,
    ...components
  },
  data: function() {
    return {
      offers: [],
      is_back: false,
      lang: window.langtag,
    }
  },
  mounted: function() {
    this.fetchFavorites();
  },
  watch: {},
  beforeRouteEnter: function(to, from, next) {
    next(vm => {
      vm.is_back = true;
    });
  },
  methods: {
    fetchFavorites: function() {
      var self = this;
      window.axios.get(window.langtag_path+'/api/favorites.json?all=1')
      .then((response) => {
        this.offers = response.data.ads;
        setTimeout(function(){
          lazyLoad('.offer__inner--favorites');
        }, 0);
      })
    },
    close: function() {
      if(this.is_back) {
        this.$router.back();
      } else {
        this.$router.push({name: 'catalog', params: {lang: this.lang}, query: { offer_type: 0 }});
      }
      console.log(this.$router, this.$route);
    },
    photoUrl(ad) {
      if(ad.ad_photos && ad.ad_photos.length > 0) {
        return ad.ad_photos[0].url;
      }
    },
    photoBackground(ad) {
      if(ad.ad_photos && ad.ad_photos.length > 0) {
        return {'background-image': 'url(\''+ad.ad_photos[0].small_url+'\')'};
      }
    },
    userBackground(user) {
      if(user.photo_url) {
        return {'background-image': 'url(\''+user.photo_url+'\')'};
      }
    },
    openFavorites() {
      if(window.catalog) {
        window.catalog.$router.push({name: 'favorites', params: {lang: this.lang}});
      } else {
        window.location.href = window.langtag_path+'/catalog/favorites';
      }
    },
    openOffer(offer_id) {
      if(window.catalog) {
        window.catalog.$router.push({name: 'offer', params: {lang: window.langtag, id: offer_id}});
      } else {
        window.location.href = window.langtag_path+'/catalog/offer/'+offer_id;
      }
    },
    remove(offer) {
      window.axios.delete(window.langtag_path+'/favorites/'+offer.id+'.json')
      .then((response) => {
        console.log(['fetch favorites!'])
        this.fetchFavorites();
      }).catch(function (error) {
        // handle error
      });
    },
    createChannel() {
      var self = this;
      var channel = createChannel({channel: "FavoritesChannel", chat_id: window.key}, {
        received(data) {
          console.log(['fetch!', 'yes']);
          self.fetchFavorites();
        }
      });
    },
  }
}
</script>
