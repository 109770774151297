<template>
  <div class="catalog__offer" v-on:mouseover="$emit('showOnMap', offer)" v-on:mouseleave="$emit('hideOnMap', offer)">
    <div class="catalog__images" v-touch:swipe.left="nextPhoto" v-touch:swipe.right="prevPhoto" v-touch:moved="movedHandler" v-touch:end="endHandler" v-touch:start="startHandler">
      <div v-if="offer.ad_photos.length > 0" class="catalog__images__inner" v-bind:class="{'catalog__images__inner--no-animation': no_animation}" v-bind:style="{'width': (100*(offer.ad_photos.length+1))+'%', 'left': (current_index*-100)+'%'}">
        <figure v-for="(photo, index) in offer.ad_photos" class="catalog__image lazy-background" v-bind:data-src="photo.url" v-bind:style="{'background-image': 'url(\''+offer.ad_photos[0].small_url+'\')', 'width': (100/(offer.ad_photos.length+1))+'%'}"></figure>
      </div>
      <router-link v-else :to="{name: 'offer', params: {lang: lang, id: offer.id}}" class="catalog__images__inner"  v-bind:class="{'offer__mainimage--no-photo': offer.is_offer, 'offer__mainimage--searching': !offer.is_offer}"></router-link>
      <nav v-if="offer.ad_photos.length > 1">
        <button class="catalog__images__goleft" v-bind:class="{'d-none': (current_index == 0)}" v-on:click.prevent="prevPhoto"></button>
        <button class="catalog__images__goright" v-bind:class="{'d-none': (current_index == (offer.ad_photos.length - 1))}" v-on:click.prevent="nextPhoto"></button>
        <ul class="catalog__images__dots">
          <li v-for="(photo, index) in offer.ad_photos" ><button v-on:click.prevent="current_index = index" class="catalog__images__dot" v-bind:class="{'active': (index == current_index)}"></button></li>
        </ul>
      </nav>
    </div>

    <div class="catalog__price">
      <div v-if="offer.price">
        <span>{{offer.price}}</span>
        <small v-if="offer.offer_type && offer.offer_type.id == 1 && offer.price">/{{i18n('addad.per_month')}}</small>
        <small v-else>&nbsp;</small>
      </div>
      <div v-else class="catalog__price__nego">{{i18n('offer.price_is_negotiable')}}</div>
      <small><span v-if="!offer.in_local_currency">{{offer.local_price}}</span><span v-else>&nbsp;</span></small>
      <router-link :to="{name: 'user', params: {lang: lang, id: offer.user.id}}" class="catalog__user" v-bind:class="['catalog__user--'+offer.user.user_type.type]" v-bind:style="userBackground(offer.user)"></router-link>
    </div>
    <div class="catalog__left">
      <strong class="catalog__ribbon">{{offer.ribbon}}</strong>
      <div class="catalog__address">
        <div class="catalog__address__short">{{offer.ad_addresses[0].place.short_name}}</div>
        <div class="catalog__address__country">{{offer.ad_addresses[0].place.country}}</div>
      </div>
    </div>
    <div class="catalog__fields">
      <div class="catalog__field" v-if="field.name != 'Price' && field.on_list && field.active && field.icon" v-for="field in offer.ad_fields">
        <component :is="'offerList'+field.type.replace('::', '')" :field="field"></component>
      </div>
    </div>

  </div>
</template>

<script>
import Vue from 'vue';
import Vue2TouchEvents from 'vue2-touch-events';
Vue.use(Vue2TouchEvents);
import lazyLoad from '../customs/lazy_load';
import i18nmixin from '../customs/i18nmixin'
import * as components from './offer_fields';

export default {
  name: 'catalog-list-single',
  props: ['offer'],
  mixins: [i18nmixin],
  components: {
    ...components
  },
  data: function() {
    return {
      current_index: 0,
      no_animation: false,
      lang: window.langtag,
      click: true,
    }
  },
  mounted: function() {
  },
  watch: {},
  methods: {
    nextPhoto: function() {
      this.click = false;
      if(this.current_index < this.offer.ad_photos.length-1) {
        this.current_index++;
      }
    },
    prevPhoto: function() {
      this.click = false;
      if(this.current_index > 0) {
        this.current_index--;
      }
    },
    userBackground: function(user) {
      if(user.photo_url) {
        return {'background-image': 'url(\''+user.photo_url+'\')'};
      }
    },
    movedHandler: function() {
      this.click = false;
    },
    endHandler: function(e) {
      var self = this;
      if(e.target.tagName == 'BUTTON') {
        return;
      }
      e.preventDefault();
      Vue.nextTick(function(){
        if(self.click) {
          self.$router.push({name: 'offer', params: {lang: self.lang, id: self.offer.id}});
        }
      });

    },
    startHandler: function(e) {
      this.click = true;
    },
  }
}
</script>
