<template>
  <div>
    <search></search>
  </div>
</template>

<script>
import Vue from 'vue'
import Search from './search.vue'

export default {
  components: {
    Search
  }
}
</script>
