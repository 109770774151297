<template>
  <div class="catalog">
    <router-view></router-view>
    <div class="container catalog__container">
      <search v-on:submit="submit"></search>
      <div class="row">
        <div v-bind:class="{'col-lg-6 d-md-none d-lg-block': map_visible, 'd-none': (!map_visible)}">
          <div id="map" class="catalog__map"><div id="map-popup" class="catalog__map__popover"></div></div>
        </div>
        <div v-bind:class="{'col-lg-6': map_visible, 'col-lg-12': (!map_visible)}">
          <div v-if="loading" class="lds-dual-ring"></div>
          <div v-else class="catalog__list">
            <catalog-list-single :key="offer.id" v-for="offer in offers" v-bind:offer="offer"  v-on:showOnMap="showOnMap" v-on:hideOnMap="hideOnMap" ></catalog-list-single>
            <div class="alert alert-info mt-2" v-if="offers.length == 0">{{i18n('search.no_results')}}</div>
          </div>
        </div>
      </div>
    </div>
  </div>
</template>

<script>
import Vue from 'vue'
import lazyLoad from '../customs/lazy_load';
import CatalogListSingle from './catalog_list_single';
import Search from './catalog-search';
import Map from 'ol/Map';
import View from 'ol/View';
import TileLayer from 'ol/layer/Tile';
import Feature from 'ol/Feature';
import Overlay from 'ol/Overlay';
import Point from 'ol/geom/Point';
import OSM from 'ol/source/OSM';
import Vector from 'ol/source/Vector';
import VectorLayer from 'ol/layer/Vector';
import {fromLonLat} from 'ol/proj';
import {Style, Icon} from 'ol/style';
import {MouseWheelZoom, defaults} from 'ol/interaction';
import markerImage from '../assets/images/marker.svg';
import markerOwner from '../assets/images/marker-owner.svg';
import markerObserver from '../assets/images/marker-observer.svg';
import markerAgent from '../assets/images/marker-agent.svg';
import markerDeveloper from '../assets/images/marker-developer.svg';
import $ from "jquery";
import i18nmixin from '../customs/i18nmixin';
import hash from "object-hash";
import Cookies from 'js-cookie';

export default {
  name: 'catalog-list',
  props: [],
  mixins: [i18nmixin],
  components: {
    CatalogListSingle,
    Search
  },
  data: function() {
    return {
      offers: [],
      loading: false,
      map: null,
      tiles_url: 'localhost:3000',
      map_visible: true,
      markerVectorLayers: [],
      current_params: {},
      popup: null,
      meta: {
        total_pages: 0,
        current_page: 0,
      },
      markers: {
        markerowner: markerOwner,
        markerobserver: markerObserver,
        markeragent: markerAgent,
        markerdeveloper: markerDeveloper,
      }
    }
  },
  mounted: function() {
    this.fetchOffers();
    var self = this;
    window.addEventListener("resize", function(e) {
      self.rebuildMap();
    });
  },
  watch: {},
  methods: {
    fetchOffers: function() {
      var self = this;
      var params = this.$route.query;
      if(this.$route.name != 'catalog' && Cookies.get('search')) {
         params = JSON.parse(Cookies.get('search'));
      }
      if(params['offer_type'] == undefined) {
        params['offer_type'] = 0;
      }
      this.current_params = JSON.parse(JSON.stringify(params));
      this.loading = true;
      window.axios.get(window.langtag_path+'/api/catalog.json', {
        params: params
      })
      .then((response) => {
        this.offers = response.data.data;
        this.meta = response.data.meta;
        window.catalogPaginationVue.$children[0].updateMeta(this.meta);
        self.loading = false;
        setTimeout(function(){
          if(self.map_visible) {
            self.buildMap();
          }
          lazyLoad('.catalog__list');
        }, 0);
      })
    },
    showOnMap: function(offer) {
      if(this.map) {
        var pixel = this.map.getPixelFromCoordinate(fromLonLat([offer.ad_addresses[0].lon, offer.ad_addresses[0].lat]));
        var features = [];
        this.map.forEachFeatureAtPixel(pixel, function(feature) {
          features.push(feature);
        });
        var isFeature = false;
        var element = document.getElementById('map-popup');
        if(!element) {
          return;
        }
        for(var i = 0; i < features.length; i++) {
          if (features[i].getId() == offer.id) {
            var feature = features[i];
            var coordinates = feature.getGeometry().getCoordinates();

            this.popup.setPosition(coordinates);
            element.innerHTML = feature.get('html');
            $(element).addClass('active');
            isFeature = true;
          }
        }
        if(!isFeature) {
          $(element).removeClass('active');
        }
      }
    },
    hideOnMap: function(offer) {
      var element = document.getElementById('map-popup');
      $(element).removeClass('active');
    },
    rebuildMap: function() {
      this.map = null;
      document.getElementById('map').innerHTML = '<div id="map-popup" class="catalog__map__popover"></div>';
      this.buildMap();
    },
    buildMap: function() {
      var self = this;
      if(!this.map) {
        var i = new MouseWheelZoom();

        var oldFn = i.handleEvent;
        i.handleEvent = function(e) {
          var type = e.type;
          if (type !== "wheel" && type !== "wheel" ) {
            return true;
          }

          if (!e.originalEvent.ctrlKey) {
            return true
          }

          oldFn.call(this,e);
        }
        this.map = new Map({
          target: document.getElementById('map'),
          interactions: defaults({mouseWheelZoom: false}).extend([i]),
          layers: [
            new TileLayer({
              source: new OSM({
                // url: '//'+window.tiles_url+'/tiles/{z}/{x}/{y}.png?t='+(new Date().getTime()),
                url: '//'+window.tiles_url+'/tiles/{z}/{x}/{y}.png',
              })
            })
          ],
          view: new View({
            maxZoom: 17,
            minZoom: 1,
            center: fromLonLat([18.5793, 52.9958]),
            zoom: 6
          })
        });
        var element = document.getElementById('map-popup');
        this.popup = new Overlay({
          element: element,
          positioning: 'bottom-center',
          stopEvent: true,
          offset: [0, -20]
        });
        this.map.addOverlay(this.popup);
        $(document).on('click', '.catalog__map__popover__inner', function(){
          self.$router.push({ name: 'offer', params: {id: this.dataset.id} });
        });
        // display popup on click
        this.map.on('click', function(evt) {
          var feature = self.map.forEachFeatureAtPixel(evt.pixel,
            function(feature) {
              return feature;
            });
          if (feature) {
            var coordinates = feature.getGeometry().getCoordinates();
            self.popup.setPosition(coordinates);
            element.innerHTML = feature.get('html');
            $(element).addClass('active');
          } else {
            $(element).removeClass('active');
          }
        });
        // change mouse cursor when over marker
        this.map.on('pointermove', function(e) {
          if (e.dragging) {
            $(element).removeClass('d-block');
            return;
          }
          var pixel = self.map.getEventPixel(e.originalEvent);
          var hit = self.map.hasFeatureAtPixel(pixel);
          self.map.getTarget().style.cursor = hit ? 'pointer' : '';
        });
      }

      for(var i = 0; i < this.markerVectorLayers.length; i++) {
        this.map.removeLayer(this.markerVectorLayers[i]);
      }
      var markers = [];
      for(var i = 0; i < this.offers.length; i++) {
        for(var j = 0; j < this.offers[i].ad_addresses.length; j++) {
          var address = this.offers[i].ad_addresses[j];
          var photo = this.offers[i].ad_photos[0];
          var fields = '<div class="catalog__map__fields">';
          for(var f = 0; f < this.offers[i].ad_fields.length; f++) {
            var field = this.offers[i].ad_fields[f];
            if(field.name != 'Price') {
              var fields = fields+'<div class="catalog__field" style="background-image:'+(field.icon ? 'url(\''+field.icon+'\')' : null)+'"></div>';
            }
          }
          fields = fields+'</div>';
          var background = '';
          var extraClass = '';
          if(photo) {
            background = 'background-image: url(\''+photo.small_url+'\')';
          } else {
            if(!this.offers[i].is_offer) {
              extraClass = 'offer__mainimage--searching';
            }
          }
          var htmlContent = '<div class="catalog__map__popover__inner" data-id="'+this.offers[i].id+'"><div style="'+background+'" alt="'+address.place.address_name+'" class="catalog__map__image '+extraClass+'"></div>';
          htmlContent += '<div class="catalog__map__ribbon">'+this.offers[i].ribbon+'</div>';
          htmlContent += '<div class="catalog__map__address">'+address.place.address_name+'</div>';
          if(this.offers[i].price) {
            htmlContent += '<div class="catalog__map__price">'+this.offers[i].local_price+'</div>';
          } else {
            htmlContent += '<div class="catalog__map__price">'+this.i18n('offer.price_is_negotiable')+'</div>';
          }
          htmlContent += '</div>';
          var marker = new Feature({
            id: this.offers[i].id,
            html: htmlContent,
            geometry: new Point(
              fromLonLat([address.lon, address.lat])
            ),  // Cordinates of New York's City Hall
          });
          marker.setId(this.offers[i].id);
          marker.setStyle(new Style({
            image: new Icon(({
              crossOrigin: 'anonymous',
              src: this.markers['marker'+this.offers[i].user.user_type.type],
            }))
          }));
          markers.push(marker);
        }
      }
      var vectorSource = new Vector({
        features: markers
      });
      var extent = vectorSource.getExtent();
      var markerVectorLayer = new VectorLayer({
        source: vectorSource,
      });
      this.map.addLayer(markerVectorLayer);
      // var mapsize = this.map.getSize();
      if(this.offers.length > 0) {
        this.map.getView().fit(extent, {padding: [80, 80, 80, 80]});
      }
      this.markerVectorLayers.push(markerVectorLayer);
    },
    toggleMap() {
      this.map_visible = !this.map_visible;
      if(this.map_visible) {
        var self = this;
        Vue.nextTick(function(){
          self.rebuildMap();
        });
      }
    },
    toggleChat() {
      window.chat.$children[0].toggleChat();
    },
    prevPageCount() {
      return Math.max(Math.min(this.meta.current_page-2, 2), 0);
    },
    goToPage(page) {
      this.current_params.page = page;
      var md5Query = hash(this.$route.query);
      var md5Selection = hash(this.current_params);
      this.submit(this.current_params);
    },
    submit(params) {
      this.current_params = JSON.parse(JSON.stringify(params));
      this.$router.push({ path: 'catalog', query: params});
      this.fetchOffers();
    }
  }
}
</script>
