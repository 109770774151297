<template>
  <div class="select select--empty">
    <div class="select__control">
      <div class="select__icon select__icon--keys"></div>
      <div class="select__content">
        <span v-if="all()">{{i18n('search.offered_by')}}</span>
        <div class="offer-type-checkbox__in-select">
          <div class="offer-type-checkbox__icon"
            v-bind:class="['offer-type-checkbox__icon--'+account.name]"
            v-for="account in model"
            v-if="account.selected">
          </div>
        </div>
      </div>
      <div class="select__arrow"></div>
    </div>
    <div class="select__dropdown">
      <div class="select__dropdown__body select__dropdown__body--keys">
        <div class="select__label">{{i18n('search.search_offers_from')}}:</div>
        <div class="row" :key="componentKey">
          <div class="col-lg-6" v-for="col in $_.chunk(model, model.length/2.0)">
            <div class="offer-type-checkbox" v-for="account in col">
              <div class="offer-type-checkbox__checkbox">
                <div class="custom-control custom-checkbox">
                  <input type="checkbox" class="custom-control-input" v-model="account.selected" v-bind:id="'search'+account.name+'Checkbox'" >
                  <label class="custom-control-label" v-bind:for="'search'+account.name+'Checkbox'"></label>
                </div>
              </div>
              <label class="offer-type-checkbox__icon" v-bind:class="['offer-type-checkbox__icon--'+account.name]" v-bind:for="'search'+account.name+'Checkbox'"></label>
              <label class="offer-type-checkbox__label" v-bind:for="'search'+account.name+'Checkbox'">{{account.label}}</label>
            </div>
          </div>
        </div>
      </div>
    </div>
  </div>
</template>

<script>
import Vue from 'vue'
import i18nmixin from '../customs/i18nmixin'

export default {
  name: 'search-select-keys',
  mixins: [i18nmixin],
  props: ['value', 'selection'],
  data: function() {
    return {
      model: [],
      selected: [],
      componentKey: 0,
    }
  },
  watch: {
    model: {
      handler: function(new_val) {
        var selected = [];
        var selectedKeys = [];
        var is_selected = false;
        for(var i = 0; i < new_val.length; i++) {
          is_selected = is_selected || new_val[i].selected;
          selected.push(new_val[i].selected);
          if(new_val[i].selected) {
            selectedKeys.push(new_val[i].name);
          }
        }
        if(!is_selected) {
          for(var i = 0; i < new_val.length; i++) {
            Vue.set(new_val[i], 'selected', this.selected[i]);
          }
          this.model = new_val;
        } else {
          this.selected = selected;
        }
        if(selectedKeys.length == new_val.length || new_val.length == 0) {
          this.$emit('update', {type: 'offer_types', value: false});
        } else {
          this.$emit('update', {type: 'offer_types', value: selectedKeys});
        }

        this.componentKey += 1;
      },
      deep: true,
    },
  },
  mounted: function(){
    this.model = this.value;
    if(this.selection.offer_types) {
      for(var i = 0; i < this.model.length; i++) {
        if(this.selection.offer_types.indexOf(this.model[i].name) != -1) {
          this.model[i].selected = true;
        } else {
          this.model[i].selected = false;
        }
      }
    } else {
        for(var i = 0; i < this.model.length; i++) {
          this.model[i].selected = true;
        }
    }
  },
  methods: {
    all: function() {
      var count = 0;
      for(var i = 0; i < this.model.length; i++) {
        if(this.model[i].selected) {
          count++;
        }
      }
      return count == this.model.length;
    }
  }
}
</script>
