<template>
  <div>
    <div class="offer" v-on:click.self="close">
      <div class="offer__inner container" v-if="offer">
        <button type="button" v-on:click.prevent="close" class="close close--absolute"><span aria-hidden="true">&times;</span></button>
        <div class="clearfix"></div>
        <div class="row row--small">
          <div class="col-lg-6">
            <div class="p-3">
              <div class="catalog__images" v-if="mobile && offer.ad_photos.length > 0">
                Nomber: {{current_index}}
                <div v-if="offer.ad_photos.length > 0" class="catalog__images__inner" v-bind:class="{'catalog__images__inner--no-animation': no_animation}" v-bind:style="{'width': (100*(offer.ad_photos.length+1))+'%', 'left': (current_index*-100)+'%'}" v-touch:swipe.left="nextMobilePhoto" v-touch:swipe.right="prevMobilePhoto">
                  <figure v-for="(photo, index) in offer.ad_photos" class="catalog__image lazy-background" v-bind:data-src="photo.url" v-bind:style="{'background-image': 'url(\''+photo.small_url+'\')', 'width': (100/(offer.ad_photos.length+1))+'%'}"></figure>
                </div>
                <router-link v-else :to="{name: 'offer', params: {lang: lang, id: offer.id}}" class="catalog__images__inner"></router-link>
                <nav v-if="offer.ad_photos.length > 1">
                  <button class="catalog__images__goleft" v-bind:class="{'d-none': (current_index == 0)}" v-on:click.prevent="prevMobilePhoto"></button>
                  <button class="catalog__images__goright" v-bind:class="{'d-none': (current_index == (offer.ad_photos.length - 1))}" v-on:click.prevent="nextMobilePhoto"></button>
                  <ul class="catalog__images__dots">
                    <li v-for="(photo, index) in offer.ad_photos" ><button v-on:click.prevent="current_index = index" class="catalog__images__dot" v-bind:class="{'active': (index == current_index)}"></button></li>
                  </ul>
                </nav>
              </div>
              <figure v-if="offer.ad_photos.length == 0" class="offer__mainimage" v-bind:class="{'offer__mainimage--no-photo': offer.is_offer, 'offer__mainimage--searching': !offer.is_offer}"></figure>
              <figure v-if="!mobile && offer.ad_photos.length > 0" v-on:click="displayImage(0)" class="offer__mainimage lazy-background" v-bind:data-src="offer.ad_photos[0].large_url" v-bind:style="{'background-image': 'url('+offer.ad_photos[0].small_url+')'}"></figure>
              <nav class="text-right">
                <a href="#" v-if="!offer.favorite" class="offer__add-to-favorites" v-on:click.prevent="addToFavorite">{{i18n('offer.add_favorite')}}</a>
                <a href="#" v-if="offer.favorite" class="offer__remove-from-favorites" v-bind:class="{'beat': beat}" v-on:click.prevent="removeFromFavorite">{{i18n('offer.remove_favorite')}}</a>
                <div class="btn-group ml-3">
                  <a href="#" class="dropdown-toggle dropdown-toggle--simple" data-toggle="dropdown" aria-haspopup="true" aria-expanded="false">
                    <i class="fas fa-share-alt"></i>
                  </a>
                  <social-sharing v-bind:url="server_url+'/catalog/offer/'+offer.id" inline-template
                    v-bind:title="offer.social_title"
                    v-bind:description="offer.social_title+'. '+offer.social_description"
                    hashtags="homestify">
                    <div class="dropdown-menu dropdown-menu--no-arrowhead" onclick="event.stopPropagation()">
                      <span class="dropdown-item button-social">
                        <network network="email">
                          <i class="fas fa-envelope"></i> Email
                        </network>
                      </span>
                      <span class="dropdown-item button-social">
                        <network network="facebook">
                          <i class="fab fa-facebook-f"></i> Facebook
                        </network>
                      </span>
                      <span class="dropdown-item button-social">
                        <network network="pinterest">
                          <i class="fab fa-pinterest"></i> Pinterest
                        </network>
                      </span>
                      <span class="dropdown-item button-social">
                        <network network="reddit">
                          <i class="fab fa-reddit"></i> Reddit
                        </network>
                      </span>
                      <span class="dropdown-item button-social">
                        <network network="telegram">
                          <i class="fab fa-telegram"></i> Telegram
                        </network>
                      </span>
                      <span class="dropdown-item button-social">
                        <network network="twitter">
                          <i class="fab fa-twitter"></i> Twitter
                        </network>
                      </span>
                      <span class="dropdown-item button-social">
                        <network network="vk">
                          <i class="fab fa-vk"></i> VKontakte
                        </network>
                      </span>
                      <span class="dropdown-item button-social">
                        <network network="weibo">
                          <i class="fab fa-weibo"></i> Weibo
                        </network>
                      </span>
                    </div>
                  </social-sharing>
                </div>
                <a href="#" v-on:click.prevent="report" v-bind:title="i18n('reports.tooltip')" class="ml-3"><i class="fas fa-exclamation-triangle"></i></a>
              </nav>
              <div class="offer__section" v-for="section in photoSections()" v-if="!mobile">
                <h4 class="offer__details-title">{{section.title}}</h4>
                <div class="offer__section__photos">
                  <figure v-for="photo in section.photos" v-on:click="displayImage(photo.index)" class="offer__minimage lazy-background" v-bind:data-src="photo.url" v-bind:style="{'background-image': 'url('+photo.small_url+')'}"></figure>
                </div>
              </div>
            </div>
          </div>
          <div class="col-lg-6">
            <div class="p-3">
              <div class="offer__title">
                <p class="h3">{{offer.ribbon}}</p>
                <h1 class="offer__address">
                  {{offer.ad_addresses[0].place.short_name}}
                  <span v-if="offer.ad_addresses[0].place.state">
                    <br>
                    {{offer.ad_addresses[0].place.state}}
                  </span>
                  <br>
                  {{offer.ad_addresses[0].place.country}}
                </h1>
              </div>
              <div v-if="offer.deleted_at" class="offer__price--negotiable">
                {{i18n('offer.deleted')}}
              </div>
              <div v-if="offer.price && !offer.deleted_at" class="offer__price">
                <span class="offer__price__inner">
                  {{offer.price}}
                  <span class="offer__price__monthly" v-if="offer.offer_type && offer.offer_type.id == 1">/{{i18n('addad.per_month')}}</span>
                  <small v-if="!offer.in_local_currency">{{offer.local_price}}</small>
                </span>
              </div>
              <div v-if="!offer.price && !offer.deleted_at" class="offer__price--negotiable">
                {{i18n('offer.price_is_negotiable')}}
              </div>
              <h4 class="offer__details-title" v-if="offersOnList()">{{i18n('offer.offer_details')}}</h4>
              <div v-else class="clearfix"></div>
              <div class="offer__details-section" v-if="offersOnList()">
                <div :key="section" v-for="(field_section, section) in fields">
                  <h3 class="offer__details-subtitle" v-if="section != 'general'">{{section}}</h3>
                  <ul class="offer__details-list">
                    <li v-for="field in field_section" v-if="field.name != 'Price' && field.active">
                      <component :is="'offer'+field.type.replace('::', '')" :field="field"></component>
                    </li>
                  </ul>
                </div>
              </div>
              <div id="offer-map" class="offer__map">

              </div>
              <section class="offer__profile">
                <div class="row">
                  <div class="col-md-7">
                    <router-link :to="{name: 'user', params: {lang: lang, id: offer.user.id}}" class="offer__profile__user-icon" v-bind:class="['offer__profile__user-icon--'+offer.user.user_type.type]" v-bind:style="userBackground(offer.user)"></router-link>
                    <div class="offer__profile__personal">
                      <h3 class="offer__profile__name"><router-link :to="{name: 'user', params: {lang: lang, id: offer.user.id}}">{{offer.user.name}}</router-link></h3>
                      <p class="offer__profile__type">
                        <router-link v-if="offer.user.team" :to="{name: 'team', params: {lang: lang, id: offer.user.team.id}}" class="offer__profile__team-icon" v-bind:style="userBackground(offer.user.team)"></router-link>
                        <span v-if="offer.user.team">
                          <router-link  v-if="offer.user.team" :to="{name: 'team', params: {lang: lang, id: offer.user.team.id}}">{{offer.user.user_type.label}}</router-link>
                          <br>
                          <router-link   :to="{name: 'team', params: {lang: lang, id: offer.user.team.id}}">{{offer.user.team.label}} {{offer.user.team.name}}</router-link>
                        </span>
                        <router-link  v-else :to="{name: 'user', params: {lang: lang, id: offer.user.id}}">{{offer.user.user_type.label}}</router-link>
                        <br />
                        <span v-if="offer.user.licence_number">{{i18n('offer.licence_number')}}: {{offer.user.licence_number}}</span>
                      </p>
                    </div>
                    <!-- <div class="clearfix"></div> -->
                    <p class="offer__profile__chat" v-if="offer.user.allow_chat && !offer.deleted_at"><a href="#" v-on:click.prevent="startConversation">{{i18n('offer.chat')}}</a></p>
                    <p class="offer__profile__phone" v-if="offer.user.allow_phone && !offer.deleted_at">
                      {{i18n('offer.call_me')}}
                      <button class="btn btn-dark btn-xs" type="button" v-on:click="showPhone" v-if="!phone_number">{{i18n('offer.show_number')}}</button>
                      <a v-bind:href="'tel:'+phone_number" v-if="phone_number">{{number_label}}</a>
                    </p>
                    <div class="clearfix"></div>
                    <div class="offer__profile__all mb-3" v-if="!offer.deleted_at">
                      <span v-if="offer.user.contact.skype">
                          <a  target="_blank" v-bind:href="'skype:'+offer.user.contact.skype" class="offer__contacts__icon offer__contacts__icon--skype"><span>{{offer.user.contact.skype}}</span></a>
                          <br>
                      </span>
                      <span  v-if="offer.user.contact.facebook">
                          <a target="_blank" v-bind:href="'http://m.me/'+offer.user.contact.facebook" class="offer__contacts__icon offer__contacts__icon--facebook">{{offer.user.contact.facebook}}</a>
                          <br>
                      </span>
                      <span v-if="offer.user.contact.whatsapp">
                          <a  target="_blank" v-bind:href="'https://wa.me/'+offer.user.contact.whatsapp" class="offer__contacts__icon offer__contacts__icon--whatsapp">{{offer.user.contact.whatsapp}}</a>
                          <br>
                      </span>
                      <span v-if="offer.user.contact.snapchat">
                          <a target="_blank" v-bind:href="'https://www.snapchat.com/add/'+offer.user.contact.snapchat" class="offer__contacts__icon offer__contacts__icon--snapchat">{{offer.user.contact.snapchat}}</a>
                          <br>
                      </span>
                      <span v-if="offer.user.contact.telegram">
                          <a target="_blank" v-bind:href="'https://t.me/'+offer.user.contact.telegram" class="offer__contacts__icon offer__contacts__icon--telegram">{{offer.user.contact.telegram}}</a>
                          <br>
                      </span>
                      <span v-if="offer.user.contact.viber">
                          <a target="_blank" v-bind:href="'viber://chat?number='+offer.user.contact.viber" class="offer__contacts__icon offer__contacts__icon--viber">{{offer.user.contact.viber}}</a>
                      </span>
                    </div>
                    <div class="clearfix"></div>
                    <div class="mb-3" v-if="offer.user.languages.length > 0">
                      <b>{{i18n('offer.known_languages')}}</b>
                      <br>
                      <div v-for="lang in offer.user.languages">{{lang.locale_name}} <small>({{lang.level_name}})</small></div>
                  </div>
                  </div>
                  <div class="col-md-5">
                    <clock v-bind:availability="offer.user.availabilities" v-if="hasClock"></clock>
                  </div>
                </div>
              </section>
            </div>
          </div>
        </div>
      </div>
    </div>
    <div class="offer__gallery" v-if="gallery_open" v-on:click.self="closeGallery">
      <button type="button" v-on:click.prevent="closeGallery" class="close"><span aria-hidden="true">&times;</span></button>
      <div class="offer__gallery__image-wraper"  v-on:click.self="closeGallery" v-bind:style="{'width': ((offer.ad_photos.length*80))+'%', left: (10-(gallery_index*80))+'%'}" v-touch:swipe.left="nextPhoto" v-touch:swipe.right="prevPhoto">
        <div class="offer__gallery__image" v-bind:class="{'current': (gallery_index ==  index? 1 : 0)}" v-on:click.self="closeGallery" v-for="(photo, index) in offer.ad_photos" v-bind:style="{opacity: (gallery_index ==  index? 1 : 0), width: (100/offer.ad_photos.length)+'%'}">
          <figure v-on:click.self="closeGallery">
            <img v-bind:src="photo.full_url" v-bind:alt="photo.ad_photo_section" v-bind:style="{'left': (gallery_index ==  index ? gallery_image_left*-1 : 0)+'px', position: 'relative'}">
            <figcaption>
              {{photo.ad_photo_section}}
            </figcaption>
          </figure>
        </div>
      </div>
      <button class="offer__gallery__goleft" v-bind:class="{'d-none': ((gallery_index == 0) && !gallery_move_left), 'panorama': gallery_move_left}" v-on:click.prevent="prevPhoto"></button>
      <button class="offer__gallery__goright" v-bind:class="{'d-none': (gallery_index == (offer.ad_photos.length - 1) && !gallery_move_right), 'panorama': gallery_move_right}" v-on:click.prevent="nextPhoto"></button>
    </div>
  </div>
</template>

<script>
import Vue from 'vue';
import Vue2TouchEvents from 'vue2-touch-events';
import SocialSharing from 'vue-social-sharing';

Vue.use(SocialSharing);
Vue.use(Vue2TouchEvents);
import lazyLoad from '../customs/lazy_load';
import $ from "jquery";
import 'bootstrap/js/dist/modal';
import i18nmixin from '../customs/i18nmixin'
import Map from 'ol/Map';
import View from 'ol/View';
import TileLayer from 'ol/layer/Tile';
import Feature from 'ol/Feature';
import Point from 'ol/geom/Point';
import OSM from 'ol/source/OSM';
import Vector from 'ol/source/Vector';
import VectorLayer from 'ol/layer/Vector';
import {fromLonLat} from 'ol/proj';
import {Style, Icon} from 'ol/style';
import {MouseWheelZoom, defaults} from 'ol/interaction';
import markerImage from '../assets/images/marker.svg';
import markerOwner from '../assets/images/marker-owner.svg';
import markerObserver from '../assets/images/marker-observer.svg';
import markerAgent from '../assets/images/marker-agent.svg';
import markerDeveloper from '../assets/images/marker-developer.svg';
import Clock from './clock';
import * as components from './offer_fields'

export default {
  name: 'catalog-offer',
  props: ['id'],
  mixins: [i18nmixin],
  components: {
    Clock,
    ...components
  },
  data: function() {
    return {
      offer: null,
      gallery_open: false,
      gallery_index: 0,
      gallery_move_left: false,
      gallery_move_right: false,
      gallery_image_width: null,
      gallery_figure_width: null,
      gallery_image_left: 0,
      map: null,
      markerVectorLayers: [],
      phone_number: null,
      number_label: null,
      current_photo: null,
      mobile: false,
      is_back: false,
      lang: window.langtag,
      current_index: 0,
      no_animation: false,
      langtag_path: '',
      server_url: '',
      beat: false,
      hasClock: true,
      markers: {
        markerowner: markerOwner,
        markerobserver: markerObserver,
        markeragent: markerAgent,
        markerdeveloper: markerDeveloper,
      },
      fields: {},
    }
  },
  mounted: function() {
    // $('body').css('overflow', 'hidden');
    $('body').addClass('offer-open');
    this.fetchOffer();
    var self = this;
    this.langtag_path = window.langtag_path;
    this.server_url = window.server_url;
    window.addEventListener("resize", function(e) {
      self.checkMobile();
    });
    this.checkMobile();
    if (typeof window.gtag === "function") {
      // console.log(['sending ga', this.$route.fullPath]);
      // window.gtag('event', 'page_view');
      window.gtag('event', 'pageview', {
        'event_category': 'pageview',
        'event_label': this.$route.fullPath,
      });
    }
  },
  beforeRouteEnter: function(to, from, next) {
    next(vm => {
      vm.is_back = from.name == 'catalog';
    });
  },
  methods: {
    buildFields: function() {
      var fields = {};
      for(var i = 0; i < this.offer.ad_fields.length; i++) {
        var field = this.offer.ad_fields[i];
        if(field.name == 'Price' || !field.active) {
          continue;
        }
        if(!field.field_section) {
          field.field_section = 'general';
        }
        if(!fields[field.field_section]) {
          fields[field.field_section] = [];
        }
        fields[field.field_section].push(field);
      }
      this.fields = fields;
    },
    offersOnList: function() {
      var offers_on_list = false;
      for(var i = 0; i < this.offer.ad_fields.length; i++) {
        if(this.offer.ad_fields[i].name != 'Price' && this.offer.ad_fields[i].active) {
          offers_on_list = true;
        }
      }
      return offers_on_list;
    },
    startHandler: function(e) {
    },
    endHandler: function(e) {
    },
    swipeLeftHandler: function(e) {
    },
    fetchOffer: function() {
      var self = this;
      window.axios.get(window.langtag_path+'/api/catalog/'+this.$route.params.id+'.json', {
        params: {}
      })
      .then((response) => {
        this.offer = response.data;
        document.title = this.offer.ribbon+' Homestify';
        for(var i = 0; i < this.offer.ad_photos.length; i++) {
          this.offer.ad_photos[i].index = i;
        }
        this.buildFields();
        this.hasClock = false;
        for(var i = 0; i < this.offer.user.availabilities.length; i++) {
          if(this.offer.user.availabilities[i].start_at) {
            this.hasClock = true;
          }
        }
        setTimeout(function(){
          lazyLoad('.offer');
          self.buildMap();
        }, 0);
      })
    },
    close: function() {
      // $('body').css('overflow', 'auto');
      $('body').removeClass('offer-open');
      if(this.is_back || !this.offer) {
        this.$router.back();
      } else {
        this.$router.push({name: 'catalog', params: {lang: this.lang}, query: { offer_type: this.offer.offer_type.id }});
      }
    },
    photoSections() {
      var sections = [];
      for(var i = 0; i < this.offer.ad_photos.length; i++) {
        var current_index = -1;
        for(var j = 0; j < sections.length; j++) {
          if(sections[j].id == this.offer.ad_photos[i].ad_photo_section_id) {
            current_index = j;
            break;
          }
        }
        if(current_index == -1) {
          current_index = sections.length;
          sections.push({
            id: this.offer.ad_photos[i].ad_photo_section_id,
            title: this.offer.ad_photos[i].ad_photo_section,
            photos: [],
          })
        }
        sections[current_index].photos.push(this.offer.ad_photos[i]);
      }
      return sections;
    },
    buildMap: function() {
      if(!this.offer) {
        return;
      }
      if(!this.map) {
        var i = new MouseWheelZoom();

        var oldFn = i.handleEvent;
        i.handleEvent = function(e) {
          var type = e.type;
          if (type !== "wheel" && type !== "wheel" ) {
            return true;
          }

          if (!e.originalEvent.ctrlKey) {
            return true
          }

          oldFn.call(this,e);
        }
        this.map = new Map({
          target: 'offer-map',
          interactions: defaults({mouseWheelZoom: false, dragAndDrop: false}).extend([i]),
          layers: [
            new TileLayer({
              source: new OSM({
                // url: '//'+window.tiles_url+'/tiles/{z}/{x}/{y}.png?t='+(new Date().getTime()),
                url: '//'+window.tiles_url+'/tiles/{z}/{x}/{y}.png',
              })
            })
          ],

          view: new View({
            maxZoom: 17,
            center: fromLonLat([this.offer.ad_addresses[0].lon, this.offer.ad_addresses[0].lat]),
            zoom: 13
          })
        });
      }

      for(var i = 0; i < this.markerVectorLayers.length; i++) {
        this.map.removeLayer(this.markerVectorLayers[i]);
      }
      var markers = [];
      for(var j = 0; j < this.offer.ad_addresses.length; j++) {
        var address = this.offer.ad_addresses[j];
        var marker = new Feature({
          geometry: new Point(
            fromLonLat([address.lon, address.lat])
          ),  // Cordinates of New York's City Hall
        });
        marker.setStyle(new Style({
          image: new Icon(({
            crossOrigin: 'anonymous',
            src: this.markers['marker'+this.offer.user.user_type.type]
          }))
        }));
        markers.push(marker);
      }
      var vectorSource = new Vector({
        features: markers
      });
      var markerVectorLayer = new VectorLayer({
        source: vectorSource,
      });
      this.map.addLayer(markerVectorLayer);
      this.markerVectorLayers.push(markerVectorLayer);
    },
    displayImage(index) {
      this.gallery_open = true;
      this.gallery_index = index;
      this.openPhoto();
    },
    closeGallery() {
      this.gallery_open = false;
    },
    openPhoto() {
      var self = this;
      // this.current_photo = null;
      self.current_photo = self.offer.ad_photos[self.gallery_index].full_url;
      setTimeout(function() {
        self.waitForImage();
      }, 0);

    },
    nextPhoto() {
      if(this.gallery_index < this.offer.ad_photos.length-1 && this.gallery_move_right == false) {
        this.gallery_index++;
        this.gallery_image_left = 0;
        this.gallery_move_right = false;
        this.gallery_move_left = false;
        this.openPhoto();
      } else if (this.gallery_move_right) {
        // this.gallery_image_left = Math.min(this.gallery_image_left+this.gallery_figure_width, this.gallery_image_width-this.gallery_figure_width);
        var destination = Math.min(this.gallery_image_left+this.gallery_figure_width, this.gallery_image_width-this.gallery_figure_width);
        this.animatePanoramic(destination);
        this.gallery_move_left = true;
        if(this.gallery_figure_width+destination>= this.gallery_image_width) {
          this.gallery_move_right = false;
        }
      }
    },
    prevPhoto() {
      if(this.gallery_index > 0 && this.gallery_move_left == false) {
        this.gallery_index--;
        this.gallery_image_left = -1;
        this.gallery_move_right = false;
        this.gallery_move_left = false;
        this.openPhoto();
      } else if (this.gallery_move_left) {
        // this.gallery_image_left = Math.max(this.gallery_image_left-this.gallery_figure_width, 0);
        var destination = Math.max(this.gallery_image_left-this.gallery_figure_width, 0);
        this.animatePanoramic(destination);
        this.gallery_move_right = true;
        if(destination <= 0) {
          this.gallery_move_left = false;
        }
      }
    },
    animatePanoramic(destination) {
      var current = this.gallery_image_left;
      var diff = destination-current;
      var perStep = diff/25;
      var self = this;
      for(let i = 1; i <=25; i++) {
        setTimeout(function(){
          self.gallery_image_left = current+perStep*i;
        }, 10*i);
      }
    },
    nextMobilePhoto: function() {
      if(this.current_index < this.offer.ad_photos.length-1) {
        this.current_index++;
      }
    },
    prevMobilePhoto: function() {
      if(this.current_index > 0) {
        this.current_index--;
      }
    },
    waitForImage() {
      var self = this;
      var actualImage = new Image();


      actualImage.onload = function() {
          var image = this;
          setTimeout(function(){
              self.gallery_image_width = image.width;
              self.gallery_figure_width = $('.offer__gallery__image.current figure').width();
              var height = image.height
              var gallery_figure_height = $('.offer__gallery__image.current figure').innerHeight();
              var scale = Math.min(1, gallery_figure_height/height);
              self.gallery_image_width = scale*self.gallery_image_width;
              console.log(['gallery size', self.gallery_image_width, self.gallery_figure_width, scale, self.gallery_figure_width, gallery_figure_height]);
              if(parseInt(self.gallery_image_width) > parseInt(self.gallery_figure_width)+10) {
                if(self.gallery_image_left == -1) {
                  self.gallery_image_left = self.gallery_image_width-self.gallery_figure_width;
                  self.gallery_move_left = true;
                  self.gallery_move_right = false;
                } else {
                  self.gallery_image_left = 0;
                  self.gallery_move_left = false;
                  self.gallery_move_right = true;
                }
            } else {
                self.gallery_image_left = 0;
                self.gallery_move_left = false;
                self.gallery_move_right = false;
            }
          }, 100);
      }

      actualImage.src = self.current_photo;
      // $('#offerGallery').on('load', function() {
      //    self.gallery_image_width = $('#offerGallery').width();
      //    self.gallery_figure_width = $('#offerGallery').parent('figure').width();
      //    if(self.gallery_image_width > self.gallery_figure_width) {
      //      if(self.gallery_image_left == -1) {
      //        self.gallery_image_left = self.gallery_image_width-self.gallery_figure_width;
      //        self.gallery_move_left = true;
      //        self.gallery_move_right = false;
      //      } else {
      //        self.gallery_image_left = 0;
      //        self.gallery_move_left = false;
      //        self.gallery_move_right = true;
      //      }
      //    }
      //    if(self.gallery_move_left || self.gallery_move_right) {
      //      setTimeout(function(){ $('#offerGallery').addClass('transition'); }, 250);
      //    } else {
      //      $('#offerGallery').removeClass('transition');
      //    }
      //    $('#offerGallery').off('load');
      // });
    },
    addToFavorite() {
      window.axios.post(window.langtag_path+'/favorites.json', {ad_id: this.offer.id})
      .then((response) => {
        this.offer.favorite = true;
        this.beat = true;
        document.getElementById("favoritesCount").classList.add('beat');
        setTimeout(function(){
          document.getElementById("favoritesCount").classList.remove('beat');
        }, 500);
      }).catch(function (error) {
          // handle error
      });
    },
    removeFromFavorite() {
      window.axios.delete(window.langtag_path+'/favorites/'+this.offer.id+'.json')
      .then((response) => {
        this.offer.favorite = false;
      }).catch(function (error) {
        // handle error
      });
    },
    userBackground(user) {
      if(user.photo_url) {
        return {'background-image': 'url(\''+user.photo_url+'\')'};
      }
    },
    startConversation() {
      window.axios.post(window.langtag_path+'/api/conversations.json', {ad_id: this.offer.id})
      .then((response) => {
        window.chat.$children[0].openChat();
        window.chat.$children[0].openConversationById(response.data.conversation_id);
      }).catch(function (error) {
        if(error.response.status != 401) {
          if(error.response.data && error.response.data.banned_at) {
            toastr.error(error.response.data.banned_at);
          }
        }
      });
    },
    showPhone() {
      var self = this;
      window.axios.post(window.langtag_path+'/api/catalog/phone_number/'+this.offer.id+'.json', {})
      .then((response) => {
        self.phone_number = response.data.number;
        self.number_label = response.data.number_label;
      });
    },
    report() {
      window.report.$children[0].fetchReport('ad', this.offer.id);
    },
    checkMobile() {
      var w = Math.max(document.documentElement.clientWidth, window.innerWidth || 0);
      var changed = false;
      if(w <= 991 && !this.mobile) {
        this.mobile = true;
        changed = true;
      } else if (w > 991 && this.mobile) {
        this.mobile = false;
        changed = true;
      }
      if(changed) {
        var self = this;
        self.map = null;
        if(document.getElementById('offer-map')) {
          document.getElementById('offer-map').innerHTML = '';
        }
        Vue.nextTick(function () {
          self.buildMap();
          lazyLoad('.offer');
        });
      }
    }
  }
}
</script>
