<template>
  <div>
    <div class="offer" v-on:click.self="close">
      <div class="offer__inner container">
        <button type="button" v-on:click.prevent="close" class="close close--absolute"><span aria-hidden="true">&times;</span></button>
        <div v-if="user">
          <router-link :to="{name: 'user', params: {lang: lang, id: user.id}}" class="offer__profile__user-icon" v-bind:class="['offer__profile__user-icon--'+user.user_type.type]" v-bind:style="userBackground(user)"></router-link>
          <div class="offer__profile__personal offer__profile__personal--border-right">
            <h3 class="offer__profile__name"><router-link :to="{name: 'user', params: {lang: lang, id: user.id}}">{{user.name}}</router-link></h3>
            <p class="offer__profile__type">
              <router-link v-if="user.team" :to="{name: 'team', params: {lang: lang, id: user.team.id}}" class="offer__profile__team-icon" v-bind:style="userBackground(user.team)"></router-link>
              <router-link v-if="user.team" :to="{name: 'team', params: {lang: lang, id: user.team.id}}">{{user.user_type.label}} <span >{{i18n('offer.in')}} {{user.team.name}}</span></router-link>
              <router-link v-else :to="{name: 'user', params: {lang: lang, id: user.id}}">{{user.user_type.label}}</router-link>
              <br />
              <span v-if="user.licence_number">{{i18n('offer.licence_number')}}: {{user.licence_number}}</span>
            </p>
          </div>
          <div class="catalog__user-contact" v-if="this.offers.length > 0">
            <p class="offer__profile__phone" v-if="user.allow_phone">
              {{i18n('offer.call_me')}}
              <button class="btn btn-dark btn-xs" type="button" v-on:click="showPhone" v-if="!phone_number">{{i18n('offer.show_number')}}</button>
              <a v-bind:href="'tel:'+phone_number" v-if="phone_number">{{number_label}}</a>
            </p>
            <div class="clearfix"></div>
            <div class="offer__profile__all">
              <a v-if="user.contact.skype" target="_blank" v-bind:href="'skype:'+user.contact.skype" class="offer__contacts__icon offer__contacts__icon--skype"></a>
              <a v-if="user.contact.facebook" target="_blank" v-bind:href="'http://m.me/'+user.contact.facebook" class="offer__contacts__icon offer__contacts__icon--facebook"></a>
              <a v-if="user.contact.whatsapp" target="_blank" v-bind:href="'https://wa.me/'+user.contact.whatsapp" class="offer__contacts__icon offer__contacts__icon--whatsapp"></a>
              <a v-if="user.contact.snapchat" target="_blank" v-bind:href="'https://www.snapchat.com/add/'+user.contact.snapchat" class="offer__contacts__icon offer__contacts__icon--snapchat"></a>
              <a v-if="user.contact.telegram" target="_blank" v-bind:href="'https://t.me/'+user.contact.telegram" class="offer__contacts__icon offer__contacts__icon--telegram"></a>
              <a v-if="user.contact.viber" target="_blank" v-bind:href="'https://viber.me/'+user.contact.viber" class="offer__contacts__icon offer__contacts__icon--viber"></a>
            </div>
          </div>
        </div>
        <div class="clearfix"></div>
        <div v-if="loading" class="lds-dual-ring"></div>
        <div v-else class="catalog__list">
          <catalog-list-single :key="offer.id" v-for="offer in offers" v-bind:offer="offer"></catalog-list-single>
          <div class="alert alert-info mt-2" v-if="offers.length == 0">{{i18n('search.no_results')}}</div>
        </div>
        <nav class="pagination justify-content-center">
          <ul  v-if="meta.total_pages > 1" class="pagination pagination-sm mb-0">
            <li class="page-item" aria-current="page" v-if="meta.current_page > 1">
              <a class="page-link" v-on:click.prevent="goToPage(1)" href="#">1</a>
            </li>
            <li class="page-item" aria-current="page" v-if="meta.current_page > 4">
              <span class="page-link">
                ...
              </span>
            </li>
            <li class="page-item" aria-current="page" v-for="n in prevPageCount()">
              <a class="page-link" v-on:click.prevent="goToPage(meta.current_page-(prevPageCount()-n)-1)" href="#">{{meta.current_page-(prevPageCount()-n)-1}}</a>
            </li>
            <li class="page-item active" aria-current="page">
              <span class="page-link">
                {{meta.current_page}}
              </span>
            </li>
            <li class="page-item" aria-current="page" v-for="n in (Math.min(meta.total_pages-meta.current_page, 2))">
              <a class="page-link" v-on:click.prevent="goToPage(meta.current_page+n)" href="#">{{meta.current_page+n}}</a>
            </li>
            <li class="page-item" aria-current="page" v-if="meta.current_page < meta.total_pages-3">
              <span class="page-link">
                ...
              </span>
            </li>
            <li class="page-item" aria-current="page" v-if="meta.current_page < meta.total_pages-2">
              <a class="page-link" v-on:click.prevent="goToPage(meta.total_pages)" href="#">{{meta.total_pages}}</a>
            </li>
          </ul>
        </nav>
      </div>
    </div>
  </div>
</template>

<script>
import Vue from 'vue'
import lazyLoad from '../customs/lazy_load';
import CatalogListSingle from './catalog_list_single';
import $ from "jquery";
import 'bootstrap/js/dist/modal';
import i18nmixin from '../customs/i18nmixin'
import markerImage from '../assets/images/marker.svg';
import Clock from './clock';
import * as components from './offer_fields'

export default {
  name: 'catalog-user',
  props: ['id'],
  mixins: [i18nmixin],
  components: {
    CatalogListSingle,
    ...components
  },
  data: function() {
    return {
      offers: [],
      is_back: false,
      lang: window.langtag,
      user: null,
      loading: false,
      phone_number: null,
      number_label: null,
      meta: {
        total_pages: 0,
        current_page: 0,
      },
    }
  },
  mounted: function() {
    $('body').addClass('offer-open');
    this.fetchOffers();
  },
  watch: {},
  beforeRouteEnter: function(to, from, next) {
    next(vm => {
      vm.is_back = true;
    });
  },
  methods: {
    fetchOffers: function(page = 1) {
      var self = this;
      this.loading = true;
      window.axios.get(window.langtag_path+'/api/catalog/user/'+this.$route.params.id+'.json', {
        params: {page: page}
      })
      .then((response) => {
        this.offers = response.data.data;
        this.meta = response.data.meta;
        this.user = response.data.user;
        this.loading = false;
        setTimeout(function() {
          lazyLoad('.catalog__list');
        }, 0);
      })
    },
    showPhone() {
      var self = this;
      window.axios.post(window.langtag_path+'/api/catalog/phone_number/'+this.offers[0].id+'.json', {})
      .then((response) => {
        self.phone_number = response.data.number;
        self.number_label = response.data.number_label;
      });
    },
    userBackground(user) {
      if(user.photo_url) {
        return {'background-image': 'url(\''+user.photo_url+'\')'};
      }
    },
    close: function() {
      $('body').removeClass('offer-open');
      if(this.is_back) {
        this.$router.back();
      } else {
        this.$router.push({name: 'catalog', params: {lang: this.lang}, query: { offer_type: 0 }});
      }
    },
    prevPageCount() {
      return Math.max(Math.min(this.meta.current_page-2, 2), 0);
    },
    goToPage(page) {
      this.fetchOffers(page);
    },
  }
}
</script>
