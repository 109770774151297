<template>
  <div class="clock-container">
    <div class="clock">
      <div class="clock__wrap">
        <div class="clock__base">
          <div class="clock__indicator"></div>
          <div class="clock__indicator"></div>
          <div class="clock__indicator"></div>
          <div class="clock__indicator"></div>
          <div class="clock__indicator"></div>
          <div class="clock__indicator"></div>
          <div class="clock__indicator"></div>
          <div class="clock__indicator"></div>
          <div class="clock__3">3</div>
          <div class="clock__6">6</div>
          <div class="clock__9">9</div>
          <div class="clock__12">12</div>
        </div>
        <span class="clock__hour" v-bind:style="{transform: 'rotate(' + hour + 'deg)'}"></span>
        <span class="clock__minute" v-bind:style="{transform: 'rotate(' + minute + 'deg)'}"></span>
        <span class="clock__second" v-bind:style="{transform: 'rotate(' + second + 'deg)'}"></span>
        <span class="clock__dot"></span>
      </div>

      <svg class="pie" viewBox="0 0 186 186" v-bind:style="{transform: 'rotate(' + rotate + 'deg)'}">
        <!-- <circle r="5" cx="10" cy="10" v-bind:stroke-dasharray="'calc('+this.path+' * 31.42 / 100) 31.42'" /> -->
        <circle r="46.5" cx="93" cy="93" v-bind:style="{'stroke-dasharray': path}" />
      </svg>
    </div>
    <p class="clock-time" >
      <div v-for="availabilityDay in availability" class="clock__day" v-bind:class="{'clock__day--active': (current_avilability && current_avilability.wday == availabilityDay.wday)}">
        <span data-toggle="tooltip" v-bind:title="i18n('addad.im_available')">
          <b>{{i18n('day_names.'+availabilityDay.wday)}}</b>:
          <span v-if="!(availabilityDay.start_at && availabilityDay.end_at)">{{i18n('clock.not_available_in_day')}}</span>
          <span v-else>
            {{format(availabilityDay.start_at)}} - {{format(availabilityDay.end_at)}}
          </span>
        </span>
      </div>
    </p>
  </div>
</template>

<script>

import i18nmixin from '../customs/i18nmixin'
import jstz from 'jstimezonedetect'
import moment from 'moment'

export default {
  name: 'clock',
  mixins: [i18nmixin],
  props: ['availability'],
  mounted: function() {
    this.setTime();
    this.setPie();
    setInterval(this.setTime, 1000);
    setTimeout(function(){
      window.$('.tooltip').remove();
      window.$('[data-toggle="tooltip"]').tooltip({ boundary: 'window' });
    }, 40);
    // setInterval(this.setPie, 1000);
  },
  data: function() {
    return {
      hour: null,
      minute: null,
      second: null,
      path: null,
      rotate: -90,
      current_avilability: null,
      not_set: false,
    }
  },
  methods: {
    format(date) {
      return moment(date).format(this.i18n('time_format'));
    },
    startAt() {
      if(this.current_avilability) {
        return moment(this.current_avilability.start_at).format(this.i18n('time_format'));
      }
    },
    endAt() {
      if(this.current_avilability) {
        return moment(this.current_avilability.end_at).format(this.i18n('time_format'));
      }
    },
    setTime() {
      var d = new Date();
    	var h = d.getHours();
    	var m = d.getMinutes();
      var s = d.getSeconds();

    	this.hour 	 = 360 * ((h+(m/60.0)) / 12);
    	this.minute = 360 * (m / 60);
      this.second = 360 * (s / 60);
      // this.minute = 360 * (s / 60);
    },
    setPie() {
      var d = new Date();
      for(var i = 0; i < this.availability.length; i++) {
        if(this.availability[i].wday == d.getDay()) {
          this.current_avilability = this.availability[i];
          break;
        }
      }
      if(!this.current_avilability) {
        this.not_set = true;
        return;
      }

      if(!this.current_avilability.start_at || !this.current_avilability.end_at) {
        this.not_set = true;
        return;
      }
      var start_date = moment(this.current_avilability.start_at);
      // console.log([start_date, this.current_avilability.start_at, this.current_avilability.end_at]);
      var start_hour = start_date.hours()+(start_date.minutes()/60);
      var end_date = moment(this.current_avilability.end_at);
      var end_hour = end_date.hours()+(end_date.minutes()/60);
      var start_deg = (360 * (start_hour / 12)) % 360;
      var end_deg = (360 * (end_hour / 12)) % 360;
      var max = 292;
      var final_deg = 0;
      // alert(this.current_avilability.start_at+' => '+start_hour+' - '+end_hour)
      // this.path = start_deg;
      if(end_hour-start_hour > 12) {
          final_deg = 360;
      } else {
          if(start_deg > end_deg) {
            final_deg = end_deg+(360-start_deg);
          } else {
            final_deg = end_deg-start_deg;
          }
      }
      var final_value = final_deg/360*max;
      if(final_value == 0) {
          final_value = max;
      }
      this.rotate = start_deg-90;
      this.path = final_value+'px, '+max+'px';
    },
  }
}
</script>
