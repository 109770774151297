<template>
  <div>
    <div class="search--back" v-if="showSearch" v-on:click.prevent="showSearch = !showSearch"></div>
    <div class="search container" v-bind:class="{'active': showSearch}">
      <search-tabs v-bind:tab="tab" v-on:change-tab="changeTab"></search-tabs>
      <button type="button" class="close close--absolute d-lg-none" v-on:click.stop="toggleSearch"><span aria-hidden="true">×</span></button>
      <div class="search__content">
        <search-buy v-if="tab == 'buy'" v-bind:selection="selection" v-on:update="setSelection" v-on:submit="submit" v-bind:settings="settings"></search-buy>
        <search-rent v-if="tab == 'rent'" v-bind:selection="selection" v-on:update="setSelection" v-on:submit="submit" v-bind:settings="settings"></search-rent>
        <button type="button" class="btn btn-danger d-lg-none btn-block" v-on:click.prevent="toggleSearch">{{i18n('search.find')}}</button>
      </div>
    </div>
    <button type="button" class="btn btn-danger d-lg-none toggle-search mt-2" v-on:click.prevent="toggleSearch"></button>
  </div>
</template>

<script>
import Vue from 'vue';
import SearchTabs from './search-tabs.vue'
import SearchBuy from './catalog-search-buy.vue'
import SearchRent from './catalog-search-rent.vue'
// import SearchService from './search-service.vue'
import queryString from 'query-string';
import $ from "jquery";
import hash from "object-hash";
import i18nmixin from '../customs/i18nmixin';
import Cookies from 'js-cookie';

export default {
  components: {
    SearchTabs,
    SearchBuy,
    SearchRent,
    // SearchService
  },
  mixins: [i18nmixin],
  data: function () {
    return {
      tab: 'buy',
      selection: {},
      allowUpdate: true,
      showSearch: false,
      settings: {},
      searchTimeout: null
    }
  },
  mounted: function() {
    this.fetchSettings();
  },
  created: function(){
    if(this.$route.query) {
      var params = this.$route.query;
      if(this.$route.name != 'catalog' && Cookies.get('search')) {
         params = JSON.parse(Cookies.get('search'));
      }
      this.selection = {};
      for(var key in params) {
        this.selection[key] = params[key];
      }
      if(this.selection.offer_type == "1") {
        this.tab = 'rent';
      }
    }
  },
  methods: {
    toggleSearch() {
      this.showSearch = !this.showSearch;
      if(this.showSearch) {
        $('body').addClass('search--open');
      } else {
        $('body').removeClass('search--open');
      }
    },
    fetchSettings() {
      window.axios.get('/'+window.langtag+'/api/search_settings.json')
      .then((response) => {
        this.settings = response.data;
      });
    },
    setSelection: function(object) {
      if(object.value === false) {
        Vue.delete(this.selection, object.type);
      } else {
        Vue.set(this.selection, object.type, object.value);
      }
      var self = this;
      Vue.nextTick(function () {
        self.submit();
      });
    },
    changeTab($event) {
      this.tab = $event;
      Vue.delete(this.selection, 'price');
      var self = this;
      Vue.nextTick(function () {
        self.submit();
      });
    },
    submit() {
      if(this.$route.name != 'catalog') {
        return;
      }
      var self = this;
      clearTimeout(this.searchTimeout);
      this.searchTimeout = setTimeout(function() {
        var md5Query = hash(self.$route.query);
        var md5Selection = hash(self.selection);
        if(md5Query != md5Selection && self.allowUpdate) {
          self.allowUpdate = false;
          self.$emit('submit', self.selection);
          Vue.nextTick(function () {
            self.allowUpdate = true;
          });
        }
      }, 800);

      // this.$router.push({ path: 'catalog', query: this.selection});
      // window.location = window.langtag_path+'/catalog?'+queryString.stringify(this.selection);
    }
  }
}
</script>
