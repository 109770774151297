<template>
  <span>
    <span v-if="field.icon" class="offer__field__icon mr-2" v-bind:style="{backgroundImage: 'url(\''+field.icon+'\')'}" v-bind:title="field.title"></span>
    <span v-else class="offer__field__icon mr-2"></span>
    <span>{{field.title}}:</span>
    <span v-if="!field.max_value">
      <span v-if="field.bool_val">
        {{i18n('general.yes')}}
      </span>
      <span v-else>
        {{i18n('general.no')}}
      </span>
    </span>
    <span v-if="field.max_value">{{field.list_value}}</span>
  </span>
</template>

<script>
import Vue from 'vue'
import i18nmixin from '../../customs/i18nmixin'

export default {
  mixins: [i18nmixin],
  components: {},
  props: ['field'],
  methods: {}
}
</script>
