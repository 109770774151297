<template>
  <div class="search__form-group">
    <div class="row">
      <div class="col-4">
        <label>{{label}}</label>
      </div>
      <div class="col-8 text-right" :key="renderKey">
        {{i18n('search.from')}}
        <input type="text" class="form-control search__plussub__input search__plussub__input--x2" v-bind:value="model[0]" v-bind:name="name+'[]'" v-on:change="checkMinValue" v-bind:placeholder="(show_minplaceholder ? minplaceholder : null)" v-on:focus="show_minplaceholder = false" v-on:blur="show_minplaceholder = true">
        {{i18n('search.to')}}
        <input type="text" class="form-control search__plussub__input search__plussub__input--x2" v-bind:value="model[1]" v-bind:name="name+'[]'" v-on:change="checkMaxValue" v-bind:placeholder="(show_maxplaceholder ? maxplaceholder : null)" v-on:focus="show_maxplaceholder = false" v-on:blur="show_maxplaceholder = true">
      </div>
    </div>
  </div>
</template>

<script>
import Vue from 'vue'
import i18nmixin from '../customs/i18nmixin'

export default {
  name: 'search-more-from-to',
  props: ['label', 'name', 'min', 'max', 'value', 'minplaceholder', 'maxplaceholder'],
  mixins: [i18nmixin],
  components: {
  },
  data: function() {
    return {
      model: 0,
      renderKey: 0,
      show_minplaceholder: true,
      show_maxplaceholder: true,
    }
  },
  mounted: function(){
    this.model = this.value;
  },
  watch: {
    value: function(val) {
      this.model = val;
    }
  },
  methods: {
    checkMinValue: function(e) {
      var min = this.min || null;
      var parsed = parseInt(e.target.value) || min;

      if(this.min) {
        parsed = Math.max(this.min, parsed);
      }
      if(this.max) {
        parsed = Math.min(this.max, parsed);
      }
      this.model[0] = parsed;
      this.$emit('input', this.model);
      this.$emit('update', {type: this.name, value: this.model});
      this.renderKey += 1;
    },
    checkMaxValue: function(e) {
      var max = this.max || null;
      var parsed = parseInt(e.target.value) || max;
      if(this.min) {
        parsed = Math.max(this.min, parsed);
      }
      if(this.max) {
        parsed = Math.min(this.max, parsed);
      }
      this.model[1] = parsed;
      this.$emit('input', this.model);
      this.$emit('update', {type: this.name, value: this.model});
      this.renderKey += 1;
    }
  }
}
</script>
