<template>
  <div class="search__tabs">
    <a class="search__tab" v-bind:class="{'search__tab--active': (tab == 'buy')}" v-on:click.prevent="$emit('change-tab', 'buy')" href="#">{{i18n('search.tabs.buy')}}</a>
    <a class="search__tab" v-bind:class="{'search__tab--active': (tab == 'rent')}" v-on:click.prevent="$emit('change-tab', 'rent')" href="#">{{i18n('search.tabs.rent')}}</a>
  </div>
</template>

<script>
import Vue from 'vue'
import i18nmixin from '../customs/i18nmixin'
export default {
  name: 'search-tabs',
  props: ['tab'],
  mixins: [i18nmixin],
}
</script>
