<template>
  <div class="select select--empty">
    <div class="select__control">
      <div class="select__icon select__icon--price"></div>
      <div class="select__content">
        <span v-if="price[0] == min && price[1] == max">{{i18n('search.price')}}</span>
        <span v-else>{{formatPrice(price[0])}}-{{formatPrice(price[1])}}</span>
      </div>
      <div class="select__arrow"></div>
    </div>
    <div class="select__dropdown select__dropdown--double"  v-if="min && max">
      <div class="select__dropdown__body">
        <div class="row row--small mb-2">
          <div class="col">
            <div class="input-group input-group-sm">
              <input type="text" v-bind:value="price[0]" class="form-control text-left form-control-sm" v-on:change="fillMinPrice">
              <div class="input-group-append">
                <span class="input-group-text">{{format.unit}}</span>
              </div>
            </div>
          </div>
          <div class="col">
            <div class="input-group input-group-sm">
              <input type="text" v-bind:value="price[1]" class="form-control text-right form-control-sm" v-on:change="fillMaxPrice">
              <div class="input-group-append">
                <span class="input-group-text">{{format.unit}}</span>
              </div>
            </div>
          </div>
        </div>
        <!-- <div class="text-center"><span class="float-left text-left" v-html="formatPrice(price[0])"></span> - <span class="pull-right float-right" v-html="formatPrice(price[1])"></span></div> -->
        <vue-slider v-model="price" v-bind:data="data" dotSize="20" tooltip="none" v-on:change="updateFormatedPrices" v-if="data.length > 0"></vue-slider>
      </div>
    </div>
  </div>
</template>

<script>
import Vue from 'vue'
import VueSlider from 'vue-slider-component'
import i18nmixin from '../customs/i18nmixin'

export default {
  name: 'search-select-price',
  mixins: [i18nmixin],
  props: ['selection', 'min', 'max'],
  components: {
    VueSlider
  },
  data: function() {
    return {
      price: [],
      data: [],
      min_price: 10,
      max_price: 100000,
      timeout: null,
      format: {
        delimiter: ",",
        format: "%u%n",
        precision: 2,
        separator: ".",
        significant: false,
        strip_insignificant_zeros: false,
        unit: "€",
      }
    }
  },
  watch: {
    min: function(val) {
      if(this.price[0] == undefined) {
        this.price[0] = val;
      }
      if(this.min != undefined && this.max != undefined) {
        this.fillData();
      }
    },
    max: function(val) {
      if(this.price[1] == undefined) {
        this.price[1] = val;
      }
      if(this.min != undefined && this.max != undefined) {
        this.fillData();
      }
    }
  },
  mounted: function(){
    this.format.delimiter = window.currency.decimal_mark;
    this.format.separator = window.currency.thousands_separator;
    if(!window.currency.symbol_first) {
      this.format.format = "%n%u";
    }
    this.format.unit = window.currency.symbol;
    if(this.selection.price) {
      this.price = [parseInt(this.selection.price[0]), parseInt(this.selection.price[1])];
    }
    // this.price = [this.min, this.max];
    if(this.min != undefined && this.max != undefined) {
      if(this.price[0] == undefined) {
        this.price[0] = this.min;
      }
      if(this.price[0] < this.min) {
        this.price[0] = this.min;
      }
      if(this.price[1] == undefined) {
        this.price[1] = this.max;
      }
      if(this.price[1] > this.max) {
        this.price[1] = this.max;
      }
      this.fillData();
    }
    this.formatedMinPrice();
    this.formatedMaxPrice();
  },
  methods: {
    fillData() {
      this.data = [];
      var jump = 10;
      for(var i = this.min; i < this.max; i += jump) {
        this.data.push(i-(i%jump));
        if(i/jump > 100) {
          jump = jump*10;
        }
      }
      this.data.push(this.max);
    },
    formatPrice(price) {
      var ending = '';
      if(price > 999999) {
        ending = 'M';
        price = Math.round(price/1000000);
      } else if (price > 999) {
        ending = 'K';
        price = Math.round(price/1000);
      }
      if(price > 999) {
        price = price.toString().split('').reverse().join('');
        var ret = [];
        for(var i = 0; i < price.length; i += 3) {
           ret.push(price.substr(i, 3).split('').reverse().join(''));
        }
        price = ret.reverse().join(this.format.separator);
      }
      return this.format.format.replace('%u', this.format.unit).replace('%n', price+ending);
    },
    simpleMinPrice() {
      this.min_price = this.price[0];
    },
    simpleMaxPrice() {
      this.max_price = this.price[1];
    },
    formatedMinPrice() {
      Vue.nextTick(() => {
        this.min_price = this.formatPrice(this.price[0]);
      });
    },
    formatedMaxPrice() {
      Vue.nextTick(() => {
        this.max_price = this.formatPrice(this.price[1]);
      });
    },
    updateFormatedPrices() {
      this.formatedMinPrice();
      this.formatedMaxPrice();
      clearTimeout(this.timeout);
      var self = this;
      this.timeout = setTimeout(function(){
        if(self.price[0] == self.min && self.max == self.price[1]) {
          self.$emit('update', {type: 'price', value: false});
        } else {
          self.$emit('update', {type: 'price', value: self.price});
        }
      }, 800);
    },
    fillMinPrice(e) {
      var min = Math.min((parseInt(e.target.value) || 0), this.price[1]);
      min = Math.max(this.min, min);
      Vue.set(this.price, 0, min);
      this.$emit('update', {type: 'price', value: this.price});
    },
    fillMaxPrice(e) {
      var max = Math.max((parseInt(e.target.value) || 0), this.price[0]);
      max = Math.min(this.max, max);
      Vue.set(this.price, 1, max);
      this.$emit('update', {type: 'price', value: this.price});
    },
    setValue: function() {
    	this.value = Math.pow(10, this.scale)
    },
  }
}
</script>
